import React from 'react';
import { MaterialIcons as Icon } from '@expo/vector-icons';

const leftIcon = (navigation, icon) => <Icon
  name={icon}
  style={{ marginLeft: 20, color: '#fff' }}
  size={30}
  onPress={() => navigation.openDrawer()}
/>;

export default leftIcon;