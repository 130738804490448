import React, { Component, useEffect, useState } from 'react';
import { StyleSheet, Alert, Linking } from 'react-native';
import Signature from 'react-native-signature-canvas';
import PreLoader from '../../components/PreLoader';
import { Box, Button, Center, Container, Content, FormControl, Heading, HStack, Icon, Input, Spacer, Stack, Text, View } from 'native-base';
import { FormContext, UserContext } from '../../stores/Store';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons } from '@expo/vector-icons';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { collection, getDocs, getFirestore, query, Timestamp, doc, updateDoc, addDoc } from "firebase/firestore";
import moment from 'moment'

export default function SignatureScreen(props) {
	const { navigation } = props;
	const { item, level } = props.route.params;
	const [user] = React.useContext(UserContext);
	const firestore = getFirestore();
	const [working, setWorking] = useState(false)
	const [formData, setFormData] = React.useState({});
	const [signature, setSignature] = useState(null);
	const [revisors, setRevisors] = useState();

	const getRevisors = async () => {
		var db = firestore;
		const q = query(collection(db, "revisors_emails"));
		const data = []
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			data.push(doc.data())

		});
		console.log(data);
		setRevisors(data);
	}

	useEffect(() => {
		setWorking(true);
		getRevisors().finally(() => {
			setWorking(false);
		});

	}, [])

	const sendEmail = async () => {
		const revisor = revisors.filter((element) => element.level === level)
		const { data } = item;
		let to;
		if (revisor.length > 1) {
			if (user.email === 'comercioexteriorimport@virbac.com') {
				const data = revisor.find((element) => element.name === 'external_commerce_import')
				to = data.email
			} else {
				const data = revisor.find((element) => element.name === 'external_commerce')
				to = data.email
			}
		} else {
			to = revisor[0].email
		}
		let body = {
			to: to,
			message: {
				subject: 'Hola, tienes una revisión pendiente del sistema CLIV',
				text: 'Tienes una revisión pendiente del sistema CLIV',
				html: ` <!DOCTYPE html>
				<html>
				<head>
				<title>CLIV</title>
				</head>
				<body>
				
				<h3>Folio ${item.ref}</h3>
				<p>La revisión vehicular del día ${moment(new Timestamp(data.startAt?.seconds, data.startAt?.nanoseconds).toDate()).format('DD/MM/YYYY')}
				 de la empresa transportista ${data.company}
				 ha sido firmada por ${renderActualUser()} por favor ingrese al sistema Cliv para firmar la revisión</p>
				
				</body>
				</html> `,
			}
		}
		await addDoc(collection(firestore, "mail"), body);
	}

	const saveDoc = async () => {
		console.log(item.ref, formData);
		const formRef = doc(firestore, "forms", item.ref);
		await updateDoc(formRef, formData);
	}

	const continueForm = () => {
		setWorking(false);
		navigation.navigate('InspectionsScreen')
	}

	async function storeImage(uri) {
		if (!uri) return;
		setWorking(true);
		console.log(uri);
		const storage = getStorage();
		let filename = uri.split('/').pop();
		const property = `${validationForm()}_signature`;
		const refImage = ref(storage, 'signatures/' + filename)
		uploadBytes(refImage, uri).then((snapshot) => {
			formData[property] = 'signatures/' + filename;
			if (level === 3) formData['complete'] = true;
			saveDoc()
				.then(() => {
					sendEmail()
					continueForm();
				})
				.catch((error) => {
					Alert.alert('Error al guardar la firma, por favor espera un momento e intenta nuevamente', error)
					setWorking(false);
				})

		})
			.catch((error) => {
				console.log(error);
				Alert.alert('Error al guardar la firma, por favor espera un momento e intenta nuevamente', error)
				setSignature(null)
				setWorking(false);
			})

	}

	const confirmVisit = (signatureImg) => {
		setSignature(signatureImg)
	}

	const handleChange = (data) => {
		setFormData((prev) => {
			return {
				...prev, ...data
			}
		});
	}

	const validationForm = () => {
		if (level === 1) {
			return 'supervisor'
		}
		if (level === 2) {
			return 'external_commerce_resp'
		}
		if (level === 3) {
			return 'patrimonial_security_resp'
		}
	}

	const renderForm = () => {
		if (level === 1) {
			return (
				<Stack>
					<FormControl.Label>Supervisor</FormControl.Label>
					<Input onChangeText={(text) => handleChange({ supervisor: text })} placeholder="Supervisor" />
				</Stack>
			)
		}
		if (level === 2) {
			return (
				<Stack>
					<FormControl.Label>Resp. de comercio exterior</FormControl.Label>
					<Input onChangeText={(text) => handleChange({ external_commerce_resp: text })} placeholder="Resp. de comercio exterior" />
				</Stack>
			)
		}
		if (level === 3) {
			return (
				<Stack>
					<FormControl.Label>Resp. de seguridad patrimonial</FormControl.Label>
					<Input onChangeText={(text) => handleChange({ patrimonial_security_resp: text })} placeholder="Resp. de seguridad patrimonial" />
				</Stack>
			)
		}
	}

	const renderActualUser = () => {
		if (level === 1) {
			return 'Supervisor'
		}
		if (level === 2) {
			return (
				"Resp. de comercio exterior"
			)
		}
		if (level === 3) {
			return (
				"Resp. de seguridad patrimonial"
			)
		}
	}

	if (working) {
		return <PreLoader></PreLoader>
	}

	const style = `.m-signature-pad--footer
    .button {
      background-color: red;
      color: #FFF;
    }`;

	return (
		<Center flex={1}>
			<SafeAreaView style={{ flex: 1, padding: 10 }}>
				<Box
					rounded="lg"
					overflow="hidden"
					width={{
						base: 'xs',
						sm: 'xl',
						md: 768,
						lg: 992,
						xl: 1280,
					}}
					height="full"
					shadow={1}
					_light={{ backgroundColor: 'gray.50' }}
					_dark={{ backgroundColor: 'gray.700' }}
				>

					<Stack p="4" space={3}>
						<FormControl>
							{renderForm()}
						</FormControl>

					</Stack>
					<View style={{
						flex: 1,
						alignItems: 'center',
						justifyContent: 'center',
						height: 550,
						padding: 10,
					}}>

						<Signature
							style={{ alignSelf: 'center' }}
							onOK={(img) => confirmVisit(img)}
							onClear={() => setSignature(null)}
							descriptionText="Firmar"
							clearText="Limpiar"
							confirmText="Confirmar"
							webStyle={style}
						// webStyle={`.m-signature-pad--footer
						// .button {
						// background-color: red;
						// color: #FFF;}`}
						/>
					</View>
					<Box>
						<HStack>
							<Spacer />
							<Button
								m={5}
								disabled={
									formData[validationForm()] === undefined ||
									signature === null
								}
								leftIcon={<Icon as={<Ionicons name="save" />} color="white" />}
								colorScheme={
									formData[validationForm()] !== undefined &&
										signature !== null
										? "info" : "gray"
								}
								// colorScheme={"info"}
								size="md"
								onPress={() => {
									storeImage(signature)
								}}
							>
								Guardar
							</Button>
						</HStack>
					</Box>
				</Box>
			</SafeAreaView>
		</Center>



	);
}

const styles = StyleSheet.create({});


