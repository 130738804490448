import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Radio,
  Text,
  FormControl,
  Input,
  HStack
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';

var numeral = require('numeral');


export default function DriverNameScreen(props) {
  const { navigation } = props;
  let [form, setForm] = React.useContext(FormContext);

  const [driver, setDriver] = React.useState('');
  const [working, setWorking] = React.useState(false);

  const handleChange = () => {
    setForm((prev) => {
      return {
        ...prev, ...{ driver }
      }
    });
  }

  const continueForm = () => {
    handleChange()
    navigation.navigate('DriverLicenceScreen')
  }


  if (working) {
    return <PreLoader />;
  }

  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <Box
          rounded="lg"
          overflow="hidden"
          width={{
            base: 'xs',
            sm: 'xl',
            md: 768,
            lg: 992,
            xl: 1280,
          }}
          height="full"
          shadow={1}
          _light={{ backgroundColor: 'gray.50' }}
          _dark={{ backgroundColor: 'gray.700' }}
        >
          <Stack p="4" space={3}>
            <Stack space={2}>
              <Heading size="md" ml="-1">
                {`Inspector: ${form.inspector}`}
              </Heading>
            </Stack>
            <Text fontWeight="400">
              {`Ingrese el nombre del chofer que representa a ${form.company}`}
            </Text>
            <FormControl isRequired>
              <Stack>
                <FormControl.Label>Escribe el nombre del chofer</FormControl.Label>
                <Input onChangeText={(text) => setDriver(text)} placeholder="Chofer" />
              </Stack>
            </FormControl>
          </Stack>
          <Spacer />
          <Box>
            <HStack>
              <Spacer />
              <Button
                disabled={driver === ''}
                rightIcon={<ChevronRightIcon color="white" />}
                colorScheme={driver !== '' ? "info" : "gray"}
                size="md"
                onPress={() => continueForm()}
              >
                Continuar
              </Button>
            </HStack>

          </Box>
        </Box>
      </SafeAreaView>
    </Center>
  );
}
