import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Radio,
  Text,
  FormControl,
  Input,
  VStack,
  KeyboardAvoidingView,
  ScrollView,
  HStack
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { isFormEnded } from '../../hooks/isFormEnd';

var numeral = require('numeral');


export default function InternalWallsScreen(props) {
  const { navigation } = props;
  let [form, setForm] = React.useContext(FormContext);
  const [formData, setFormData] = React.useState({});
  const [working, setWorking] = React.useState(false);

  const handleChange = (data) => {
    setFormData((prev) => {
      return {
        ...prev, ...data
      }
    });
  }
  const handleChangeForm = (data) => {
    const formData = { ...form }
    formData.questions.internal_walls = { data, order: 12 };
    console.log(formData);
    setForm({ ...form, ...formData });
  }

  const continueForm = () => {
    handleChangeForm(formData)
    if (formData.strange_sounds_internal_walls.value || formData.secret_compartment_internal_walls.value || formData.modified_screws_internal_walls.value) {
      setWorking(true)
      form.endAt = new Date();
      isFormEnded(() => navigation.navigate('HomeScreen'), form)
    } else {
      navigation.navigate('TrailerBaseScreen')
    }

  }


  if (working) {
    return <PreLoader />;
  }


  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <KeyboardAvoidingView
          h={{
            // base: "400px",
            lg: "auto",
          }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <VStack
            rounded="lg"
            overflow="hidden"
            width={{
              base: 'xs',
              sm: 'xl',
              md: 768,
              lg: 992,
              xl: 1280,
            }}
            height="full"
            shadow={1}
            _light={{ backgroundColor: 'gray.50' }}
            _dark={{ backgroundColor: 'gray.700' }}
            flex="1"
            justifyContent="flex-end"
          >
            <ScrollView
              _contentContainerStyle={{
                px: "20px",
                mb: "4",
                minW: "72",
              }}
            >
              <Stack p="4" space={3}>
                <Stack space={2}>
                  <Heading size="md" ml="-1">
                    {`Inspector: ${form.inspector}`}
                  </Heading>
                </Stack>
                <Text fontWeight="400">
                  {`Criterios para evaluar las preguntas siguientes responde `}
                  <Text fontWeight="bold">SI </Text>
                  <Text >o</Text>
                  <Text fontWeight="bold"> NO </Text>
                  {`según corresponda`}
                </Text>
                <Text fontWeight="400">
                  {`12) Pared lateral detecha e izquierdas internas`}
                </Text>
                <FormControl>
                  <Stack space={2}>
                    <FormControl isRequired>
                      <Stack>
                        <FormControl.Label>Al golpear la pared con un martillo se escucha un sonido solido
                        </FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ strange_sounds_internal_walls: { value: nextValue, q: 'Al golpear la pared con un martillo se escucha un sonido solido', critic: true } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </Stack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.strange_sounds_internal_walls.observations = text} placeholder="Observaciones" />
                    </Stack>
                    <FormControl isRequired>
                      <Stack>
                        <FormControl.Label>Se detectan doble fondo o compartimientos secreto </FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ secret_compartment_internal_walls: { value: nextValue, q: 'Se detectan doble fondo o compartimientos secreto', critic: true } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </Stack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.secret_compartment_internal_walls.observations = text} placeholder="Observaciones" />
                    </Stack>
                    <FormControl>
                      <Stack>
                        <FormControl.Label>Se observan enmendaduras o parches a lo largo y ancho de la pared</FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ amendments_internal_walls: { value: nextValue, q: 'Se observan enmendaduras o parches a lo largo y ancho de la pared' } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </Stack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.amendments_internal_wall.observations = text} placeholder="Observaciones" />
                    </Stack>
                    <FormControl>
                      <Stack>
                        <FormControl.Label>Se detecta algún olor extraño
                        </FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ strange_odour_internal_walls: { value: nextValue, q: 'Se detecta algún olor extraño' } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </Stack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.strange_odour_internal_walls.observations = text} placeholder="Observaciones" />
                    </Stack>
                    <FormControl>
                      <Stack>
                        <FormControl.Label>Se observa que la pared se encuentra dañada con hoyos o no cuenta con la misma
                        </FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ has_holes_internal_walls: { value: nextValue, q: 'Se observa que la pared se encuentra dañada con hoyos o no cuenta con la misma' } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </Stack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.has_holes_internal_walls.observations = text} placeholder="Observaciones" />
                    </Stack>
                    <FormControl isRequired>
                      <VStack>
                        <FormControl.Label>Se detectan modificaciones en los tornillos o tuercas o se muestran flojos</FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ modified_screws_internal_walls: { value: nextValue, q: 'Se detectan modificaciones en los tornillos o tuercas o se muestran flojos', critic: true } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </VStack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.modified_screws_internal_walls.observations= text} placeholder="Observaciones" />
                    </Stack>

                  </Stack>
                </FormControl>
              </Stack>
            </ScrollView>
            <Spacer />
            <Box>
              <HStack>
                <Spacer />
                <Button
                  disabled={formData.amendments_internal_walls === undefined ||
                    formData.modified_screws_internal_walls === undefined ||
                    formData.strange_sounds_internal_walls === undefined ||
                    formData.secret_compartment_internal_walls === undefined ||
                    formData.has_holes_internal_walls === undefined ||
                    formData.strange_odour_internal_walls === undefined
                  }
                  rightIcon={<ChevronRightIcon color="white" />}
                  colorScheme={formData.amendments_internal_walls !== undefined &&
                    formData.modified_screws_internal_walls !== undefined &&
                    formData.strange_sounds_internal_walls !== undefined &&
                    formData.secret_compartment_internal_walls !== undefined &&
                    formData.has_holes_internal_walls !== undefined &&
                    formData.strange_odour_internal_walls !== undefined
                    ? "info" : "gray"
                  }
                  size="md"
                  onPress={() => continueForm()}
                >
                  Continuar
                </Button>
              </HStack>

            </Box>
          </VStack>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </Center>
  );
}
