import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Radio,
  Text,
  Select,
  CheckIcon,
  FormControl,
  Input,
  HStack
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { collection, getDocs, getFirestore, query } from "firebase/firestore";

var numeral = require('numeral');


export default function TransportingCompanyScreen(props) {
  const { navigation } = props;
  const firestore = getFirestore();
  let [form, setForm] = React.useContext(FormContext);
  const [company, setCompany] = React.useState("")
  const [companies, setCompanies] = React.useState([]);
  const [showInput, setShowInput] = React.useState(false);
  const [working, setWorking] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      setWorking(true)
      var db = firestore;
      const q = query(collection(db, "transporting_companies"),);
      const data = []
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        data.push(doc.data())
      });
      data.push({ name: "Otra" })
      console.log(data);
      setCompanies(data)
    }
    getData()
      .then(() => {
        setWorking(false)
      })
      .catch((error) => {
        console.log(error);
        setWorking(false)
      })
  }, [])

  const handleChange = () => {
    setForm((prev) => {
      return {
        ...prev, ...{ company }
      }
    });
  }

  const continueForm = () => {
    handleChange()
    navigation.navigate('DriverNameScreen')
  }


  if (working) {
    return <PreLoader />;
  }

  const pickerValues = companies.map((element) => <Select.Item label={element.name} value={element.name} key={element.name} />)

  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <Box
          rounded="lg"
          overflow="hidden"
          width={{
            base: 'xs',
            sm: 'xl',
            md: 768,
            lg: 992,
            xl: 1280,
          }}
          height="full"
          shadow={1}
          _light={{ backgroundColor: 'gray.50' }}
          _dark={{ backgroundColor: 'gray.700' }}
        >
          <Stack p="4" space={3}>
            <Stack space={2}>
              <Heading size="md" ml="-1">
                {`Inspector: ${form.inspector}`}
              </Heading>
            </Stack>
            <Text fontWeight="400">
              Indique la empresa transportista
            </Text>
            <Select
              selectedValue={company}
              minWidth="200"
              accessibilityLabel="Empresa transportista"
              placeholder="Empresa transportista"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => {
                if (itemValue === 'Otra') {
                  setCompany(itemValue)
                  setShowInput(true)
                } else {
                  setCompany(itemValue)
                  setShowInput(false)
                }

              }}
            >
              {pickerValues}
            </Select>
          </Stack>
          {showInput && <FormControl isRequired>
            <Stack>
              <FormControl.Label>Escribe el nombre de la empresa transportista</FormControl.Label>
              <Input onChangeText={(text) => setCompany(text)} placeholder="Compañia" />
            </Stack>
          </FormControl>}
          <Spacer />
          <Box>
            <HStack>
              <Spacer />
              <Button
                disabled={company === '' && company !== 'Otra'}
                rightIcon={<ChevronRightIcon color="white" />}
                colorScheme={company !== '' && company !== 'Otra' ? "info" : "gray"}
                size="md"
                onPress={() => continueForm()}
              >
                Continuar
              </Button>
            </HStack>

          </Box>
        </Box>
      </SafeAreaView>
    </Center>
  );
}
