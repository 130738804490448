import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Radio,
  Text,
  Select,
  CheckIcon,
  FormControl,
  Input,
  KeyboardAvoidingView,
  VStack,
  ScrollView,
  HStack
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { collection, getDocs, getFirestore, query } from "firebase/firestore";

var numeral = require('numeral');


export default function VehicleKindScreen(props) {
  const { navigation } = props;
  const firestore = getFirestore();
  let [form, setForm] = React.useContext(FormContext);
  const [vehicle, setVehicle] = React.useState("")
  const [vehicles, setVehicles] = React.useState([]);
  const [showInput, setShowInput] = React.useState(false);
  const [working, setWorking] = React.useState(false);
  const [formData, setFormData] = React.useState({});

  console.log(form.isArriving);

  React.useEffect(() => {
    const getData = async () => {
      setWorking(true)
      var db = firestore;
      const q = query(collection(db, "vehicles_types"),);
      const data = []
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        data.push(doc.data())
      });
      data.push({ name: "Otro" })
      console.log(data);
      setVehicles(data)
    }
    getData()
      .then(() => {
        setWorking(false)
      })
      .catch((error) => {
        console.log(error);
        setWorking(false)
      })
  }, [])

  const handleChange = (data) => {
    setFormData((prev) => {
      return {
        ...prev, ...data
      }
    });
  }
  const handleChangeForm = (data) => {
    form = { ...form, ...data }
    setForm(form);
  }

  const continueForm = () => {
    console.log(form);
    handleChangeForm(formData)
    navigation.navigate('VehicleImagesScreen')
  }

  const checkForm = () => {
    let ban = true;
    if (form.isArriving) {

      ban = (formData.vehicle && formData.ExternHeight && formData.ExternLenght && formData.ExternWidth)
    } else {
      ban = (formData.vehicle && formData.InternHeight && formData.InternLenght && formData.InternWidth && formData.ExternHeight && formData.ExternLenght && formData.ExternWidth)

    }
    return ban;
  }


  if (working) {
    return <PreLoader />;
  }

  const pickerValues = vehicles.map((element) => <Select.Item label={element.name} value={element.name} key={element.name} />)

  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <KeyboardAvoidingView
          h={{
            // base: "400px",
            lg: "auto",
          }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <VStack
            rounded="lg"
            overflow="hidden"
            width={{
              base: 'xs',
              sm: 'xl',
              md: 768,
              lg: 992,
              xl: 1280,
            }}
            height="full"
            shadow={1}
            _light={{ backgroundColor: 'gray.50' }}
            _dark={{ backgroundColor: 'gray.700' }}
            flex="1"
            justifyContent="flex-end">
            <ScrollView
              _contentContainerStyle={{
                px: "20px",
                mb: "4",
                minW: "72",
              }}
            >

              <Stack p="4" space={3}>
                <Stack space={2}>
                  <Heading size="md" ml="-1">
                    {`Inspector: ${form.inspector}`}
                  </Heading>
                </Stack>
                <Text fontWeight="400">
                  Indique el tipo de vehículo
                </Text>
                <Select
                  selectedValue={vehicle}
                  minWidth="200"
                  accessibilityLabel="Tipo de vehículo"
                  placeholder="Tipo de vehículo"
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size="5" />,
                  }}
                  mt={1}
                  onValueChange={(itemValue) => {
                    if (itemValue === 'Otro') {
                      setVehicle(itemValue)
                      handleChange({ vehicle: itemValue })
                      setShowInput(true)
                    } else {
                      setVehicle(itemValue)
                      handleChange({ vehicle: itemValue })
                      setShowInput(false)
                    }

                  }}
                >
                  {pickerValues}
                </Select>
              </Stack>
              {showInput && <FormControl isRequired>
                <Stack p="4">
                  <FormControl.Label>Escribe el tipo de vehículo</FormControl.Label>
                  <Input onChangeText={(text) => {
                    setVehicle(text)
                    handleChange({ vehicle: text })
                  }} placeholder="Vehículo" />
                </Stack>
              </FormControl>}
              {
                vehicle !== '' &&
                (
                  <Stack p="4" space={5}>
                    {!form.isArriving && <Box>
                      <Text fontSize="md" fontWeight="bold">Internas</Text>
                      <Stack>
                        <FormControl.Label>Alto</FormControl.Label>
                        <Input keyboardType="numeric" variant="underlined" p={2} placeholder="Alto" onChangeText={(text) => {
                          handleChange({ InternHeight: text })
                        }} />
                      </Stack>
                      <Stack>
                        <FormControl.Label>Ancho</FormControl.Label>
                        <Input keyboardType="numeric" variant="underlined" p={2} placeholder="Ancho" onChangeText={(text) => {
                          handleChange({ InternWidth: text })
                        }} />
                      </Stack>
                      <Stack>
                        <FormControl.Label>Largo</FormControl.Label>
                        <Input keyboardType="numeric" variant="underlined" p={2} placeholder="Largo" onChangeText={(text) => {
                          handleChange({ InternLenght: text })
                        }} />
                      </Stack>
                    </Box>}
                    <Box>
                      <Text fontSize="md" fontWeight="bold">Externas</Text>
                      <Stack>
                        <FormControl.Label>Alto</FormControl.Label>
                        <Input keyboardType="numeric" variant="underlined" p={2} placeholder="Alto" onChangeText={(text) => {
                          handleChange({ ExternHeight: text })
                        }} />
                      </Stack>
                      <Stack>
                        <FormControl.Label>Ancho</FormControl.Label>
                        <Input keyboardType="numeric" variant="underlined" p={2} placeholder="Ancho" onChangeText={(text) => {
                          handleChange({ ExternWidth: text })
                        }} />
                      </Stack>
                      <Stack>
                        <FormControl.Label>Largo</FormControl.Label>
                        <Input keyboardType="numeric" variant="underlined" p={2} placeholder="Largo" onChangeText={(text) => {
                          handleChange({ ExternLenght: text })
                        }} />
                      </Stack>
                    </Box>
                  </Stack>
                )
              }
            </ScrollView>
            <Spacer />
            <Box>
              <HStack>
                <Spacer />
                <Button
                  disabled={!checkForm()}
                  rightIcon={<ChevronRightIcon color="white" />}
                  colorScheme={checkForm() ? "info" : "gray"}
                  size="md"
                  onPress={() => continueForm()}
                >
                  Continuar
                </Button>
              </HStack>

            </Box>
          </VStack>
          {/* </Box> */}
        </KeyboardAvoidingView>
      </SafeAreaView>
    </Center>
  );
}
