import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { ActivityIndicator, Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Radio,
  Text,
  Circle,
  Input,
  Pressable,
  Icon,
  HStack,
  Image,
  Switch,
  FlatList,
  VStack,
  Progress
} from 'native-base';
import { Camera } from 'expo-camera';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import { getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';

var numeral = require('numeral');


export default function PendingImagesScreen(props) {
  const { navigation, route } = props;
  let [form, setForm] = React.useState(route.params.item || {});
  const firestore = getFirestore();
  const cameraRef = React.createRef()
  const [working, setWorking] = React.useState(false);
  const [showCamera, setShowCamera] = React.useState(false);
  const [isCameraReady, setIsCameraReady] = React.useState(false)
  const [hasPermission, setHasPermission] = React.useState(null);
  const [palletImage, setPalletImage] = React.useState(null);
  const [lockImage, setLockImage] = React.useState(null);
  const [imagesUris, setImagesUris] = React.useState({});
  const [kind, setKind] = React.useState(route.params.kind || 'pallet_images')
  React.useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  // React.useEffect(() => {
  //   console.log(imagesUris);
  //   if (!uploading && working) {
  //     // continueForm()
  //   }
  // }, [imagesUris, uploading, working])

  const saveDoc = async () => {


    // await setDoc(doc(firestore, "forms", uuid.v4()), form);
    await updateDoc(refDoc, imagesUris);
  }

  const continueForm = (body) => {
    setWorking(false);
    const refDoc = doc(firestore, "forms", form.ref);
    if (body.lock_image) {
      body.endAt = new Date();
    }

    // handleChange()
    // navigation.navigate('BumperScreen')
    console.log('imagesUris', body);
    updateDoc(refDoc, body).then((ref) => {
      setWorking(false);
      Alert.alert('Atención',
        'La solicitud fue actualizada correctamente',
        [
          {
            text: 'Ok', onPress: () => {
              navigation.navigate('PendingScreen')
            }
          }
        ])

    })
      .catch((error) => {
        console.log(error);
        setWorking(false);
      })
  }


  async function storeImage(uri, storage, route) {
    let filename = uri.split('/').pop();
    await fetch(uri)
      .then((response) => {
        response.blob()
          .then((blob) => {
            console.log(blob);
            if (blob) {
              const refImage = ref(storage, route + '/' + filename)
              uploadBytes(refImage, blob).then((snapshot) => {
                if (route === 'pallet_images') {
                  const data = { ...imagesUris };
                  data['pallet_image'] = (route + '/' + filename)
                  setImagesUris(prev => {
                    return { ...prev, ...data }
                  })
                  continueForm(data)
                }
                if (route === 'lock_images') {
                  const data = { ...imagesUris };
                  data['lock_image'] = (route + '/' + filename)
                  setImagesUris(prev => {
                    return { ...prev, ...data }
                  })
                  continueForm(data)
                }

              })
                .catch((error) => {
                  console.log(error);
                  setWorking(false);
                })
            }

          })
      })
      .catch((error) => {
        console.log(error);
        setWorking(false);
      })
  }

  const uploadImages = () => {
    console.log('uploading');
    setWorking(true);
    // setUploading(true)
    const storage = getStorage();
    const promises = [
      // storeImage(palletImage.uri, storage, 'pallet_images'),
      // storeImage(lockImage.uri, storage, 'lock_images')
    ]
    if (kind === 'pallet_images') {
      storeImage(palletImage.uri, storage, 'pallet_images')
        // .finally(() => {
        //   continueForm()
        // })
        .catch((error) => {
          console.log(error);
          setWorking(false)
        })
    } else {
      storeImage(lockImage.uri, storage, 'lock_images')
        // .finally(() => {
        //   continueForm()
        // })
        .catch((error) => {
          console.log(error);
          setWorking(false)
        })
    }
    // if (palletImage) {
    //   promises.push(storeImage(palletImage.uri, storage, 'pallet_images'))
    // }
    // if (lockImage) {
    //   promises.push(storeImage(lockImage.uri, storage, 'lock_images'))
    // }
    // Promise.all(promises)
    //   .finally(() => {
    //     continueForm()
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setWorking(false)
    //   })
  }


  const takePicture = async () => {
    if (cameraRef.current) {
      let photo = await cameraRef.current.takePictureAsync({
        quality: 0.5
      });
      if (kind === 'pallet_images') {
        setPalletImage(photo)
      }
      if (kind === 'lock_images') {
        setLockImage(photo)
      }
      setShowCamera(false);
      setIsCameraReady(false);
      console.log(photo);
    }
  }

  if (working) {
    return (
      <Center flex={1}>
        <Box w="90%">
          <Center mb="10">
            <Heading size="md"> Subiendo imagenes</Heading>
          </Center>
          <ActivityIndicator size={40} color={Colors.tintColor} />
        </Box>
      </Center>
    );
  }

  if (showCamera) {
    return (
      <Center flex={1}>
        <SafeAreaView style={{ flex: 1, padding: 10 }}>
          <Box
            rounded="lg"
            overflow="hidden"
            width={{
              base: 'xs',
              sm: 480,
              md: 768,
              lg: 992,
              xl: 1280,
            }}
            height="full"
            shadow={1}
            _light={{ backgroundColor: 'gray.50' }}
            _dark={{ backgroundColor: 'gray.700' }}
          >
            <Camera
              ratio={1.1}
              ref={cameraRef}
              style={{ width: '100%', height: '100%' }}
              onCameraReady={() => {
                setIsCameraReady(true)
              }}
            >
              <Pressable
                onPress={() => {
                  setShowCamera(false);
                  setIsCameraReady(false);
                }}
                backgroundColor="black"
              >
                <Icon as={<MaterialIcons name="close" />} color="white" size={8} />
              </Pressable>
              <Spacer />
              {isCameraReady && <Center mb="2">
                <Pressable
                  onPress={() => {
                    takePicture()
                  }}
                >
                  <Circle size={50} bg={Colors.tintColor}>
                    <Icon as={<MaterialIcons name="camera" />} color="white" size={8} />
                  </Circle>
                </Pressable>
              </Center>}
            </Camera>
          </Box>
        </SafeAreaView>
      </Center>
    );
  }

  if (kind === 'pallet_images') {
    return (
      <Center flex={1}>
        <SafeAreaView style={{ flex: 1, padding: 10 }}>
          <Box
            rounded="lg"
            overflow="hidden"
            width={{
              base: 'xs',
              sm: 'xl',
              md: 768,
              lg: 992,
              xl: 1280,
            }}
            height="full"
            shadow={1}
            _light={{ backgroundColor: 'gray.50' }}
            _dark={{ backgroundColor: 'gray.700' }}
          >
            <Stack p="4" space={3}>
              <Stack space={2}>
                <Heading size="md" ml="-1">
                  {`Inspector: ${form.inspector}`}
                </Heading>
              </Stack>
              {
                palletImage ?
                  <HStack space="2">
                    <Image resizeMode="cover" alt="vehicle_image" size={{
                      base: 'xl',
                      sm: '140',
                      md: '3xl',
                      lg: 992,
                      xl: 1280,
                    }} source={{ uri: palletImage.uri }} />
                    <Icon
                      onPress={() => {
                        setPalletImage(null)
                      }}
                      position="absolute"
                      top="2%"
                      right="5%"
                      backgroundColor="#ffffff30"
                      as={<MaterialIcons name="remove-circle-outline" />} color="black" size={8} >
                    </Icon>
                  </HStack>
                  :
                  !form.pallet_image ? <><Text fontWeight="400">
                    {`tomar fotografia de tarima`}
                  </Text>
                    <Spacer />
                    <Button
                      rightIcon={<Icon as={<MaterialIcons name="camera" />} color="white" size={8} />}
                      colorScheme={"info"}
                      size="md"
                      onPress={() => {
                        setKind('pallet_images')
                        setShowCamera(true)
                      }}
                    >
                      Tomar Foto
                    </Button></> : null}
            </Stack>
            <Spacer />
            <Box>
              <HStack>
                <Spacer />
                <Button
                  isLoading={working}
                  disabled={!(palletImage || lockImage)}
                  rightIcon={<ChevronRightIcon color="white" />}
                  colorScheme={(palletImage || lockImage) ? "info" : "gray"}
                  size="md"
                  onPress={() => uploadImages()}
                >
                  Guardar
                </Button>
              </HStack>

            </Box>
          </Box>
        </SafeAreaView>
      </Center>
    );
  }

  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <Box
          rounded="lg"
          overflow="hidden"
          width={{
            base: 'xs',
            sm: 'xl',
            md: 768,
            lg: 992,
            xl: 1280,
          }}
          height="full"
          shadow={1}
          _light={{ backgroundColor: 'gray.50' }}
          _dark={{ backgroundColor: 'gray.700' }}
        >
          <Stack p="4" space={3}>
            <Stack space={2}>
              <Heading size="md" ml="-1">
                {`Inspector: ${form.inspector}`}
              </Heading>
            </Stack>
          </Stack>
          <Stack p="4" space={3}>
            {
              lockImage ?
                <HStack space="2">
                  <Image resizeMode="cover" alt="vehicle_image" size={{
                    base: 'xl',
                    sm: '140',
                    md: '3xl',
                    lg: 992,
                    xl: 1280,
                  }} source={{ uri: lockImage.uri }} />
                  <Icon
                    onPress={() => {
                      setLockImage(null)
                    }}
                    position="absolute"
                    top="2%"
                    right="5%"
                    backgroundColor="#ffffff30"
                    as={<MaterialIcons name="remove-circle-outline" />} color="black" size={8} >
                  </Icon>
                </HStack>
                :
                !form.lock_image ? <>
                  <Text fontWeight="400">
                    {`tomar fotografia de candado`}
                  </Text>
                  <Spacer />
                  <Button
                    rightIcon={<Icon as={<MaterialIcons name="camera" />} color="white" size={8} />}
                    colorScheme={"info"}
                    size="md"
                    onPress={() => {
                      setKind('lock_images')
                      setShowCamera(true)
                    }}
                  >
                    Tomar Foto
                  </Button>
                </> : null}

          </Stack>
          <Spacer />
          <Box>
            <HStack>
              <Spacer />
              <Button
                isLoading={working}
                disabled={!(palletImage || lockImage)}
                rightIcon={<ChevronRightIcon color="white" />}
                colorScheme={(palletImage || lockImage) ? "info" : "gray"}
                size="md"
                onPress={() => uploadImages()}
              >
                Guardar
              </Button>
            </HStack>

          </Box>
        </Box>
      </SafeAreaView>
    </Center>
  );
}
