import * as React from 'react';
import { ImageBackground, Platform, KeyboardAvoidingView, Alert } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import styles from './styles';
import { LoggContext, PermissionContext, UserContext } from '../../stores/Store';
import config from '../../utils/config';

import CryptoJS from "react-native-crypto-js";
import Axios from 'axios';
import { loggedValues, permissionsValues } from '../../utils/enums';
import Colors from '../../constants/Colors';
import { getAuth, onAuthStateChanged, FacebookAuthProvider, signInWithEmailAndPassword } from 'firebase/auth';
import { Box, Button, Icon, Image, Input, Spinner, Text, View } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
const backgroundImage = require('../../assets/images/logo.png');
const icon = require('../../assets/images/logo.png');

const SignInScreen = () => {

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [credentials, setCredentials] = React.useState({
        username: '',
        password: null,
        rememberMe: true
    });
    const [, setLogged] = React.useContext(LoggContext);
    const [, setPermission] = React.useContext(PermissionContext);
    const [, setUser] = React.useContext(UserContext);

    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
        if (user) {

            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            AsyncStorage.setItem('appData', JSON.stringify(user))
                .then(() => {
                    config.api.headers.Authorization = `Bearer ${user.stsTokenManager.accessToken}`;
                    const Manager = user.email !== "seguridad@virbac.com"
                    setUser(user);
                    if (Manager) {
                        setLogged(loggedValues.LOGGED);
                        setPermission(permissionsValues.MANAGER);
                    } else {
                        setLogged(loggedValues.LOGGED);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // setLogged(loggedValues.SHEETS);

                });
            // ...
        } else {
            // User is signed out
            // ...
        }
    });

    const getData = async () => {
        try {
            const value = await AsyncStorage.getItem('user');
            if (value !== null) {
                const data = JSON.parse(value);

                const bytes = CryptoJS.AES.decrypt(data.ciphertext.toString(), 'cirilo');
                const plaintext = bytes.toString(CryptoJS.enc.Utf8);
                const bytes2 = CryptoJS.AES.decrypt(data.ciphertext2.toString(), 'mantecas');
                const plaintext2 = bytes2.toString(CryptoJS.enc.Utf8);
                const body = {
                    username: plaintext,
                    password: plaintext2,
                };
                body.rememberMe = true;
                setCredentials(body);
            }
        } catch (e) {
            console.log(e);

            Alert.alert('Error', 'ha ocurrido un error al recuperar tu usuario');
        }
    };

    React.useEffect(() => {
        getData();
    }, []);

    const handleChange = (value) => {
        const data = { ...credentials, ...value };
        setCredentials(data);
    };

    const SignIn = () => {
        setIsSubmitting(true);
        const uri = `${config.api.host}token`;
        const body = { ...credentials }
        delete body.rememberMe
        signInWithEmailAndPassword(auth, body.username, body.password)
            .then(async () => {
                if (credentials.rememberMe) {
                    const ciphertext = CryptoJS.AES.encrypt(credentials.username, 'cirilo');
                    const ciphertext2 = CryptoJS.AES.encrypt(credentials.password, 'mantecas');
                    const data = JSON.stringify({
                        ciphertext: ciphertext.toString(),
                        ciphertext2: ciphertext2.toString(),
                    });
                    await AsyncStorage.setItem('user', data);
                } else {
                    await AsyncStorage.removeItem('user');
                }
                setIsSubmitting(false);
            })
            .catch((error) => {
                console.log(error);
                // setLogged(loggedValues.SHEETS);
                if (Platform.OS == 'web') {
                    alert(`Ha ocurrido un error al iniciar sesión ${JSON.stringify(error)}`);
                } else {
                    Alert.alert('Ha ocurrido un error al iniciar sesión', JSON.stringify(error));
                }

                setIsSubmitting(false);
            });
    }

    if (Platform.OS === 'web') {
        return (
            <Box backgroundColor={Colors.tintColor} justifyContent="center">
                <ImageBackground square source={backgroundImage} style={styles.bgImageWeb}>
                    <View style={styles.bgWeb}>
                        {/* <Image source={backgroundImage} style={{ height: 150, width: 150}} /> */}
                        <View style={{ marginBottom: 5, flexDirection: 'row' }}>
                            <Ionicons active name="person" style={{ color: '#fff', fontSize: 30 }} />
                            <Input
                                width="90%"
                                placeholder="Correo eléctronico"
                                placeholderTextColor="#fff"
                                color="#000"
                                keyboardType="default"
                                autoCapitalize="none"
                                autoCorrect={false}
                                textContentType="username"
                                value={credentials.username}
                                onChangeText={value => handleChange({ username: value.length > 0 ? value : null })}
                            />
                        </View>
                        <View style={{ marginBottom: 5, marginTop: 15, flexDirection: 'row' }}>
                            <Ionicons name="lock-open-outline" style={{ color: '#fff', fontSize: 30 }} />
                            <Input
                                width="90%"
                                placeholder="Contraseña"
                                placeholderTextColor="#fff"
                                secureTextEntry
                                color="#000"
                                textContentType="password"
                                value={credentials.password}
                                onChangeText={value => handleChange({ password: value.length > 0 ? value : null })}
                            />
                        </View>
                        <Button
                            rounded="md"
                            block
                            style={styles.buttonLogin}
                            onPress={() => {
                                SignIn();
                            }
                            }
                        >
                            {!isSubmitting ? <Text style={{ color: 'white' }}>Inciar sesión</Text> : <Spinner color={'rgba(231,76,60,1)'} />}
                        </Button>
                    </View>
                </ImageBackground>
            </Box>
        )
    }


    return (
        <Box>
            <KeyboardAvoidingView
                h={{
                    base: "400px",
                    lg: "auto",
                }}
                behavior={Platform.OS === "ios" ? "padding" : "height"}
            >
                <ImageBackground square source={backgroundImage} style={styles.bgImage}>
                    <View style={styles.bg}>
                        <View style={{ marginBottom: 5, flexDirection: 'row' }}>
                            <Ionicons active name="person" style={{ color: '#fff', fontSize: 30 }} />
                            <Input
                                width="90%"
                                placeholder="Correo eléctronico"
                                placeholderTextColor="#fff"
                                keyboardType="default"
                                autoCapitalize="none"
                                autoCorrect={false}
                                style={{ color: '#fff' }}
                                textContentType="username"
                                value={credentials.username}
                                onChangeText={value => handleChange({ username: value.length > 0 ? value : null })}
                            />
                        </View>
                        <View style={{ marginBottom: 5, marginTop: 15, flexDirection: 'row' }}>
                            <Ionicons name="lock-open-outline" style={{ color: '#fff', fontSize: 30 }} />
                            <Input
                                width="90%"
                                placeholder="Contraseña"
                                placeholderTextColor="#fff"
                                secureTextEntry
                                style={{ color: '#fff' }}
                                textContentType="password"
                                value={credentials.password}
                                onChangeText={value => handleChange({ password: value.length > 0 ? value : null })}
                            />
                        </View>
                        <Button
                            rounded="md"
                            block
                            style={styles.buttonLogin}
                            onPress={() => {
                                SignIn();
                            }
                            }
                        >
                            {!isSubmitting ? <Text style={{ color: 'white' }}>Inciar sesión</Text> : <Spinner color={'rgba(231,76,60,1)'} />}
                        </Button>
                    </View>
                </ImageBackground>
            </KeyboardAvoidingView>
        </Box>
    );
}

export default SignInScreen;