import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Radio,
  Text,
  Circle,
  Input,
  Pressable,
  Icon,
  HStack,
  Image,
  Switch,
  FormControl,
  KeyboardAvoidingView
} from 'native-base';
import { Camera } from 'expo-camera';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

var numeral = require('numeral');


export default function PlateNumberScreen(props) {
  const { navigation } = props;
  let [form, setForm] = React.useContext(FormContext);
  const cameraRef = React.createRef()
  const [driver, setDriver] = React.useState('');
  const [working, setWorking] = React.useState(false);
  const [showCamera, setShowCamera] = React.useState(false);
  const [isCameraReady, setIsCameraReady] = React.useState(false)
  const [hasPermission, setHasPermission] = React.useState(null);
  const [plateNumber, setPlateNumber] = React.useState('');
  const [plateNumberImage, setPlateNumberImage] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const handleChange = (fileRef) => {
    setForm((prev) => {
      return {
        ...prev, ...{ plate_number: plateNumber, plate_number_image: fileRef }
      }
    });
  }

  const continueForm = () => {

    // handleChange()
    navigation.navigate('DeclarationScreen')
  }


  async function storeImage(uri) {
    setWorking(true);
    const storage = getStorage();
    let filename = uri.split('/').pop();
    fetch(uri)
      .then((response) => {
        response.blob()
          .then((blob) => {
            console.log(blob);
            if (blob) {
              const refImage = ref(storage, 'plate_numbers/' + filename)
              uploadBytes(refImage, blob).then((snapshot) => {
                handleChange('plate_numbers/' + filename)
                setWorking(false);
                continueForm();
              })
                .catch((error) => {
                  console.log(error);
                  setWorking(false);
                })
            }

          })
      })
      .catch((error) => {
        console.log(error);
        setWorking(false);
      })
  }


  const takePicture = async () => {
    if (cameraRef.current) {
      let photo = await cameraRef.current.takePictureAsync({
        quality: 0.5
      });
      setPlateNumberImage(photo);
      setShowCamera(false);
      setIsCameraReady(false);
      console.log(photo);
    }
  }

  if (working) {
    return <PreLoader />;
  }

  if (showCamera) {
    return (
      <Center flex={1}>
        <SafeAreaView style={{ flex: 1, padding: 10 }}>
          <Box
            rounded="lg"
            overflow="hidden"
            width={{
              base: 'xs',
              sm: 480,
              md: 768,
              lg: 992,
              xl: 1280,
            }}
            height="full"
            shadow={1}
            _light={{ backgroundColor: 'gray.50' }}
            _dark={{ backgroundColor: 'gray.700' }}
          >
            <Camera
              ratio={1.1}
              ref={cameraRef}
              style={{ width: '100%', height: '100%' }}
              onCameraReady={() => {
                setIsCameraReady(true)
              }}
            >
              <Pressable
                onPress={() => {
                  setShowCamera(false);
                  setIsCameraReady(false);
                }}
                backgroundColor="black"
              >
                <Icon as={<MaterialIcons name="close" />} color="white" size={8} />
              </Pressable>
              <Spacer />
              {isCameraReady && <Center mb="2">
                <Pressable
                  onPress={() => {
                    takePicture()
                  }}
                >
                  <Circle size={50} bg={Colors.tintColor}>
                    <Icon as={<MaterialIcons name="camera" />} color="white" size={8} />
                  </Circle>
                </Pressable>
              </Center>}
            </Camera>
          </Box>
        </SafeAreaView>
      </Center>
    );
  }

  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <Box
          rounded="lg"
          overflow="hidden"
          width={{
            base: 'xs',
            sm: 'xl',
            md: 768,
            lg: 992,
            xl: 1280,
          }}
          height="full"
          shadow={1}
          _light={{ backgroundColor: 'gray.50' }}
          _dark={{ backgroundColor: 'gray.700' }}
        >
          <Stack p="4" space={3}>
            <Stack space={2}>
              <Heading size="md" ml="-1">
                {`Inspector: ${form.inspector}`}
              </Heading>
            </Stack>
            <Text fontWeight="400">
              {`Ingrese el código de placa`}
            </Text>
            {plateNumberImage ?
              (
                <KeyboardAvoidingView
                  h={{
                    base: "400px",
                    lg: "auto",
                  }}
                  behavior={Platform.OS === "ios" ? "padding" : "height"}
                >
                  <Box>
                    <Stack>
                      <Center>
                        <HStack space={4} width={{
                          base: 'xs',
                          sm: 450,
                          md: 768,
                          lg: 992,
                          xl: 1280,
                        }}>
                          <Image resizeMode="contain" alt="driver_licence" height="3xs" width={{
                            base: 'xs',
                            sm: 480,
                            md: 768,
                            lg: 992,
                            xl: 1280,
                          }} source={{ uri: plateNumberImage.uri }} />
                          <Icon
                            onPress={() => {
                              setPlateNumberImage(null)
                            }}
                            position="absolute"
                            top={1}
                            right={1}
                            backgroundColor="#ffffff30"
                            as={<MaterialIcons name="remove-circle-outline" />} color="black" size={8} >
                          </Icon>
                        </HStack>
                      </Center>
                      <HStack mt="5%" width={{
                        base: 'xs',
                        sm: 'lg',
                        md: 768,
                        lg: 992,
                        xl: 1280,
                      }} alignItems="center" space={4}>
                        <FormControl isRequired>
                          <Stack >
                            <FormControl.Label>Escribe el código de placa</FormControl.Label>
                            <Input defaultValue={plateNumber} onChangeText={(text) => setPlateNumber(text)} placeholder="Código de placa" />
                          </Stack>
                        </FormControl>
                      </HStack>
                    </Stack>
                  </Box>
                </KeyboardAvoidingView>
              )
              :
              (<Button
                rightIcon={<Icon as={<MaterialIcons name="camera" />} color="white" size={8} />}
                colorScheme={"info"}
                size="md"
                onPress={() => setShowCamera(true)}
              >
                Tomar Foto
              </Button>)
            }
          </Stack>
          <Spacer />
          <Box>
            <HStack>
              <Spacer />
              <Button
                disabled={(!plateNumberImage || !plateNumber)}
                rightIcon={<ChevronRightIcon color="white" />}
                colorScheme={(plateNumberImage && plateNumber) ? "info" : "gray"}
                size="md"
                onPress={() => storeImage(plateNumberImage.uri)}
              >
                Continuar
              </Button>
            </HStack>

          </Box>
        </Box>
      </SafeAreaView>
    </Center>
  );
}
