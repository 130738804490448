import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import SignInScreen from '../screens/auth/SignInScreen';
import { LoggContext, PermissionContext } from '../stores/Store';
import { navigationOptions } from '../constants/Navigation';
import { loggedValues, permissionsValues } from '../utils/enums';
import HomeStack, { AprovationsHistoryStack, AprovationsStack, ClientsStack, EmployeeStack, ManagerStack, PendingRequestStack, SettingsStack } from './StacksNavigator';
import { createDrawerNavigator, DrawerItemList, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import SettingsScreen from '../screens/SettingsScreen';
import { Body, Container, Header, Content, View, Text, Badge, Box } from 'native-base';
import { MaterialIcons as Icon } from '@expo/vector-icons';
import { Image, Platform, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Colors from '../constants/Colors';
import leftIcon from '../components/LeftIcon';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();


const styles = StyleSheet.create({
  drawerHeader: {
    padding: 0,
    margin: 0,
    backgroundColor: Colors.tintColor,
    height: 100,
    position: 'relative',
    top: 0,
    alignItems: 'center',
  },

  drawerImage: {
    alignSelf: 'stretch',
    height: 80,
    left: 0,
    right: 0,
    width: null,
    borderRadius: 0,
    backgroundColor: Colors.tintColor,

  },
  menuText: {
    fontSize: Platform.OS === 'ios' ? 18 : 15,
    // marginBottom: 12,
    // marginTop: 12,
  }
});


const CustomDrawerContent = props => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Image
        source={require('../assets/images/header.png')}
        style={styles.drawerImage}
      />
      <Box>
        <DrawerItemList {...props}
        />
      </Box>
    </SafeAreaView>
  )
};

const AppNavigator = () => {
  const [logged] = React.useContext(LoggContext);
  const [permission] = React.useContext(PermissionContext);
  const renderStack = () => {
    if (logged === loggedValues.LOGGED) {
      if (permission === permissionsValues.MANAGER) {
        return (
          <Drawer.Navigator
            initialRouteName="Aprovations"
            screenOptions={navigationOptions}
            drawerContent={CustomDrawerContent}
            drawerStyle={
              {
                backgroundColor: '#f5f5f5',
                activeTintColor: '#666',
                activeBackgroundColor: 'transparent',
                inactiveTintColor: '#666',
                headerTintColor: 'white',
                itemsContainerStyle: {
                  marginVertical: 0,
                }
              }
            }>
            <Drawer.Screen
              name="Home"
              component={ManagerStack}
              options={({ navigation }) => ({
                headerShown: false,
                drawerLabel: () => <Text style={styles.menuText}>{'Administrador'}</Text>,
                drawerIcon: ({ tintColor }) => (<Icon name="home" size={26} />),
              })}
            />
            <Drawer.Screen
              name="SettingsStack"
              component={SettingsStack}
              options={({ navigation }) => ({
                headerShown: false,
                drawerLabel: () => <Text style={styles.menuText}>{'Configuración'}</Text>,
                drawerIcon: ({ tintColor }) => (<Icon name="settings" size={26} />),
              })}
            />
          </Drawer.Navigator>
        )
      }

      return (
        <Drawer.Navigator
          initialRouteName="Home"
          screenOptions={navigationOptions}
          drawerContent={CustomDrawerContent}
          drawerStyle={
            {
              backgroundColor: '#f5f5f5',
              activeTintColor: '#666',
              activeBackgroundColor: 'transparent',
              inactiveTintColor: '#666',
              headerTintColor: 'white',
              itemsContainerStyle: {
                marginVertical: 0,
              }
            }
          }>
          <Drawer.Screen
            name="Home"
            component={HomeStack}
            options={({ navigation }) => ({
              headerShown: false,
              drawerLabel: () => <Text style={styles.menuText}>{'Solicitudes'}</Text>,
              drawerIcon: ({ tintColor }) => (<Icon name="home" size={26} />),
            })}
          />
          <Drawer.Screen
            name="PendingRequestStack"
            component={PendingRequestStack}
            options={({ navigation }) => ({
              headerShown: false,
              drawerLabel: () => <Text style={styles.menuText}>{'Solicitudes Pendientes'}</Text>,
              drawerIcon: ({ tintColor }) => (<Icon name="pending-actions" size={26} />),
            })}
          />
          <Drawer.Screen
            name="SettingsStack"
            component={SettingsStack}
            options={({ navigation }) => ({
              headerShown: false,
              drawerLabel: () => <Text style={styles.menuText}>{'Configuración'}</Text>,
              drawerIcon: ({ tintColor }) => (<Icon name="settings" size={26} />),
            })}
          />
        </Drawer.Navigator>
      )
    }

    return (
      <Stack.Navigator
        screenOptions={navigationOptions}
      >
        <Stack.Screen name="SingIn"
          component={SignInScreen}
          options={{
            headerShown: false
          }} />
      </Stack.Navigator>)
  }
  return (
    <NavigationContainer>
      {
        renderStack()
      }
    </NavigationContainer>
  );
}

export default AppNavigator;