import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { ActivityIndicator, Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Radio,
  Text,
  Circle,
  Input,
  Pressable,
  Icon,
  HStack,
  Image,
  Switch,
  FlatList,
  VStack,
  Progress
} from 'native-base';
import { Camera } from 'expo-camera';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import { getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';

var numeral = require('numeral');


export default function VehicleImagesScreen(props) {
  const { navigation } = props;
  let [form, setForm] = React.useContext(FormContext);
  const cameraRef = React.createRef()
  const [driver, setDriver] = React.useState('');
  const [working, setWorking] = React.useState(false);
  const [showCamera, setShowCamera] = React.useState(false);
  const [isCameraReady, setIsCameraReady] = React.useState(false)
  const [hasPermission, setHasPermission] = React.useState(null);
  const [vehicleImages, setVehicleImages] = React.useState([]);
  const [vehicleImagesUris, setVehicleImagesUris] = React.useState([]);
  let progress = 0;

  React.useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const handleChange = () => {
    setForm((prev) => {
      return {
        ...prev, ...{ vehicles_images: vehicleImagesUris }
      }
    });
  }

  const continueForm = () => {
    handleChange()
    setWorking(false);
    // handleChange()
    navigation.navigate('BumperScreen')
  }


  async function storeImage(uri, storage) {
    let filename = uri.split('/').pop();
    await fetch(uri)
      .then((response) => {
        response.blob()
          .then((blob) => {
            console.log(blob);
            if (blob) {
              const refImage = ref(storage, 'vehicle_images/' + filename)
              // uploadBytesResumable(refImage, blob)
              //   .on(storage.TaskEvent.STATE_CHANGED,
              //     snapshot => {
              //       const progress = (
              //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              //       console.log(`Progress: ${progress}%`);
              //       if (snapshot.state === storage.TaskState.RUNNING) {
              //         console.log('file uploading...')
              //       }
              //       // ...etc
              //     },
              //     error => console.log(error.code),
              //     async () => {
              //       const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
              //       console.log(downloadURL);
              //       // the web storage url for our file
              //     })

              uploadBytes(refImage, blob).then((snapshot) => {
                const data = vehicleImagesUris;
                data.push('vehicle_images/' + filename)
                setVehicleImagesUris(data)
                if (progress < 100) {
                  progress += 100 / vehicleImages.length
                  console.log('progress', progress);
                }
              })
                .catch((error) => {
                  console.log(error);
                  setWorking(false);
                })
            }

          })
      })
      .catch((error) => {
        console.log(error);
        setWorking(false);
      })
  }

  const uploadImages = () => {
    console.log('uploading');
    setWorking(true);
    const storage = getStorage();
    const promises = vehicleImages.map((element) => storeImage(element.uri, storage))
    console.log(promises);
    Promise.all(promises)
      .then(() => {
        continueForm()
      })
      .catch((error) => {
        console.log(error);
        setWorking(false)
      })
  }


  const takePicture = async () => {
    if (cameraRef.current) {
      let photo = await cameraRef.current.takePictureAsync({
        quality: 0.5
      });
      const photos = vehicleImages;
      vehicleImages.push(photo)
      setVehicleImages(vehicleImages);
      setShowCamera(false);
      setIsCameraReady(false);
      console.log(photo);
    }
  }

  if (working) {
    return (
      <Center flex={1}>
        <Box w="90%">
          <Center mb="10">
            <Heading size="md"> Subiendo imagenes</Heading>
          </Center>
          <Progress value={progress} mx="4" />
        </Box>
      </Center>
    );
  }

  if (showCamera) {
    return (
      <Center flex={1}>
        <SafeAreaView style={{ flex: 1, padding: 10 }}>
          <Box
            rounded="lg"
            overflow="hidden"
            width={{
              base: 'xs',
              sm: 480,
              md: 768,
              lg: 992,
              xl: 1280,
            }}
            height="full"
            shadow={1}
            _light={{ backgroundColor: 'gray.50' }}
            _dark={{ backgroundColor: 'gray.700' }}
          >
            <Camera
              ratio={1.1}
              ref={cameraRef}
              style={{ width: '100%', height: '100%' }}
              onCameraReady={() => {
                setIsCameraReady(true)
              }}
            >
              <Pressable
                onPress={() => {
                  setShowCamera(false);
                  setIsCameraReady(false);
                }}
                backgroundColor="black"
              >
                <Icon as={<MaterialIcons name="close" />} color="white" size={8} />
              </Pressable>
              <Spacer />
              {isCameraReady && <Center mb="2">
                <Pressable
                  onPress={() => {
                    takePicture()
                  }}
                >
                  <Circle size={50} bg={Colors.tintColor}>
                    <Icon as={<MaterialIcons name="camera" />} color="white" size={8} />
                  </Circle>
                </Pressable>
              </Center>}
            </Camera>
          </Box>
        </SafeAreaView>
      </Center>
    );
  }

  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <Box
          rounded="lg"
          overflow="hidden"
          width={{
            base: 'xs',
            sm: 'xl',
            md: 768,
            lg: 992,
            xl: 1280,
          }}
          height="full"
          shadow={1}
          _light={{ backgroundColor: 'gray.50' }}
          _dark={{ backgroundColor: 'gray.700' }}
        >
          <Stack p="4" space={3}>
            <Stack space={2}>
              <Heading size="md" ml="-1">
                {`Inspector: ${form.inspector}`}
              </Heading>
            </Stack>
            <Text fontWeight="400">
              {`Ingrese hasta 10 fotografias del vehiculo`}
            </Text>
            {vehicleImages.length > 0 ?
              (

                <Stack space={2}>
                  {vehicleImages.length < 10 && <Button
                    rightIcon={<Icon as={<MaterialIcons name="camera" />} color="white" size={8} />}
                    colorScheme={"info"}
                    size="md"
                    onPress={() => setShowCamera(true)}
                  >
                    Tomar Foto
                  </Button>}
                  <Center>
                    <FlatList
                      data={vehicleImages}
                      numColumns={3}
                      keyExtractor={(item, index) => { `${index}` }}
                      renderItem={({ item, index }) => (
                        <HStack space="2">
                          <Image resizeMode="cover" alt="vehicle_image" size={{
                            base: 'xl',
                            sm: '140',
                            md: '3xl',
                            lg: 992,
                            xl: 1280,
                          }} source={{ uri: item.uri }} />
                          <Icon
                            onPress={() => {
                              const nData = [...vehicleImages]
                              nData.splice(index, 1)
                              setVehicleImages(nData)
                            }}
                            position="absolute"
                            top="2%"
                            right="5%"
                            backgroundColor="#ffffff30"
                            as={<MaterialIcons name="remove-circle-outline" />} color="black" size={8} >
                          </Icon>
                        </HStack>
                      )}
                    /></Center>
                </Stack>

              )
              :
              (
                <>
                  <Spacer />
                  <Button
                    rightIcon={<Icon as={<MaterialIcons name="camera" />} color="white" size={8} />}
                    colorScheme={"info"}
                    size="md"
                    onPress={() => setShowCamera(true)}
                  >
                    Tomar Foto
                  </Button>
                </>
              )
            }
          </Stack>
          <Spacer />
          <Box>
            <HStack>
              <Spacer />
              <Button
                disabled={(vehicleImages.length === 0)}
                rightIcon={<ChevronRightIcon color="white" />}
                colorScheme={(vehicleImages.length > 0) ? "info" : "gray"}
                size="md"
                onPress={() => uploadImages()}
              >
                Continuar
              </Button>
            </HStack>

          </Box>
        </Box>
      </SafeAreaView>
    </Center>
  );
}
