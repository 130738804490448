const React = require('react-native');
import { Platform } from 'react-native';
const { StyleSheet, Dimensions } = React;

const deviceHeight = Dimensions.get('window').height;
const deviceWidth = Dimensions.get('window').width;

export default
  StyleSheet.create({
    container: {
      flex: 1,
      //right: (Platform.OS === 'ios') ? -1.5 : -2,
      height: deviceHeight,
      // marginTop: (Platform.OS === 'ios') ? 0 : -10,

    },
    content: { flex: 1 },
    bgImage: {
      height: deviceHeight,
      //width: '100%',
      top: 0,
      left: 0,
    },
    shadow: {
      width: deviceWidth,
      alignSelf: 'stretch',
      height: deviceHeight,
      backgroundColor: 'transparent',
    },
    error: {
      marginTop: 0,
    },
    buttonLogin: {
      marginBottom: 5,
      backgroundColor: '#a40e12',
      marginTop: 15,
    },
    bg: {
      flex: 1,
      marginTop: (deviceHeight / 2) - 65,
      backgroundColor: 'transparent',
      paddingTop: 0,
      paddingLeft: 35,
      paddingRight: 35,
      paddingBottom: (Platform.OS === 'ios') ? 70 : 50,
    },
    bgWeb: {
      flex: 1,
      // width: '50%',
      alignSelf: 'center',
      marginTop: (deviceHeight / 2) - 65,
      backgroundColor: 'transparent',
      paddingTop: 0,
      paddingLeft: 35,
      paddingRight: 35,
      paddingBottom: (Platform.OS === 'ios') ? 70 : 50,
    },
    bgImageWeb: {
      height: (deviceHeight),
      width: (deviceWidth / 3),
      alignSelf: 'center'
    },
  }
  );
