import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from '../screens/HomeScreen';
import { navigationOptions } from '../constants/Navigation';
import LogoTitle from '../components/LogoTitle';
import leftIcon from '../components/LeftIcon';
import SettingsScreen from '../screens/SettingsScreen';
import SelectVehicleScreen from '../screens/form/selectVehicle';
import TransportingCompanyScreen from '../screens/form/transportingCompany';
import DriverNameScreen from '../screens/form/driverName';
import DriverLicenceScreen from '../screens/form/driverLicence';
import CirculationCardScreen from '../screens/form/circulationCard';
import StampNumberScreen from '../screens/form/StampNumber';
import PlateNumberScreen from '../screens/form/plateNumber';
import DeclarationScreen from '../screens/form/declaration';
import VehicleKindScreen from '../screens/form/vehicleKind';
import VehicleImagesScreen from '../screens/form/vehicleImages';
import BumperScreen from '../screens/form/bumper';
import TiresScreen from '../screens/form/tires';
import TractFloorScreen from '../screens/form/tractFloor';
import GasTankScreen from '../screens/form/gasTank';
import CabinScreen from '../screens/form/cabin';
import AirTankScreen from '../screens/form/airTanks';
import FifthWheelScreen from '../screens/form/fifthWheel';
import TransmissionAxisScreen from '../screens/form/transmissionAxis';
import ExhaustPipeScreen from '../screens/form/exhaustPipe';
import EngineScreen from '../screens/form/engine';
import ExternWallsScreen from '../screens/form/externalWalls';
import InternalWallsScreen from '../screens/form/internalWalls';
import TrailerBaseScreen from '../screens/form/trailerBase';
import DoorsScreen from '../screens/form/doors';
import RoofScreen from '../screens/form/roof';
import FrontWallScreen from '../screens/form/frontWall';
import FloorScreen from '../screens/form/floor';
import ValidationFormScreen from '../screens/form/validation';
import { Box, Button, Icon } from 'native-base';
import MainScreen from '../screens/management/main';
import InspectionsScreen from '../screens/management/inspections';
import CompaniesScreen from '../screens/management/companies';
import { Ionicons } from '@expo/vector-icons';
import CreateTransportingCompanyScreen from '../screens/management/createTransportingCompany';
import SignatureScreen from '../screens/management/signature';
import InspectionsCompleteScreen from '../screens/management/inspectionsComplete';
import PendingScreen from '../screens/pending/pending';
import PendingImagesScreen from '../screens/pending/pendingImages';
import SignedInspectionsScreen from '../screens/management/SignedInspections';

const Stack = createStackNavigator();

const HomeStack = ({ navigation, route }) => {
    return (
        <Stack.Navigator
            initialRouteName="HomeScreen"
            screenOptions={{ ...navigationOptions }}>
            <Stack.Screen name="HomeScreen"
                options={({ navigation }) => ({
                    headerLeft: () => leftIcon(navigation, 'menu'),
                    headerTitle: props => <LogoTitle {...props} />
                })}
                component={HomeScreen} />
            <Stack.Screen name="SelectVehicleScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={SelectVehicleScreen} />
            <Stack.Screen name="TransportingCompanyScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={TransportingCompanyScreen} />
            <Stack.Screen name="DriverNameScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={DriverNameScreen} />
            <Stack.Screen name="DriverLicenceScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={DriverLicenceScreen} />
            <Stack.Screen name="CirculationCardScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={CirculationCardScreen} />
            <Stack.Screen name="StampNumberScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={StampNumberScreen} />
            <Stack.Screen name="PlateNumberScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={PlateNumberScreen} />
            <Stack.Screen name="DeclarationScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={DeclarationScreen} />
            <Stack.Screen name="VehicleKindScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={VehicleKindScreen} />
            <Stack.Screen name="VehicleImagesScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={VehicleImagesScreen} />
            <Stack.Screen name="BumperScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={BumperScreen} />
            <Stack.Screen name="TiresScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={TiresScreen} />
            <Stack.Screen name="TractFloorScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={TractFloorScreen} />
            <Stack.Screen name="GasTankScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={GasTankScreen} />
            <Stack.Screen name="CabinScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={CabinScreen} />
            <Stack.Screen name="AirTankScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={AirTankScreen} />
            <Stack.Screen name="FifthWheelScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={FifthWheelScreen} />
            <Stack.Screen name="TransmissionAxisScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={TransmissionAxisScreen} />
            <Stack.Screen name="ExhaustPipeScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={ExhaustPipeScreen} />
            <Stack.Screen name="EngineScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={EngineScreen} />
            <Stack.Screen name="ExternWallsScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={ExternWallsScreen} />
            <Stack.Screen name="InternalWallsScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={InternalWallsScreen} />
            <Stack.Screen name="TrailerBaseScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={TrailerBaseScreen} />
            <Stack.Screen name="DoorsScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={DoorsScreen} />
            <Stack.Screen name="RoofScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={RoofScreen} />
            <Stack.Screen name="FrontWallScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={FrontWallScreen} />
            <Stack.Screen name="FloorScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={FloorScreen} />
            <Stack.Screen name="ValidationFormScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={ValidationFormScreen} />

        </Stack.Navigator>
    );
}

export const ManagerStack = ({ navigation, route }) => {
    return (
        <Stack.Navigator
            screenOptions={{ ...navigationOptions }}>
            <Stack.Screen name="MainScreen"
                options={({ navigation }) => ({
                    headerLeft: () => leftIcon(navigation, 'menu'),
                    headerTitle: props => <LogoTitle {...props} />,
                    // headerRight: () => <Box />,
                })}
                component={MainScreen} />
            <Stack.Screen name="InspectionsScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={InspectionsScreen} />
            <Stack.Screen name="InspectionsCompleteScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={InspectionsCompleteScreen} />
            <Stack.Screen name="SignedInspectionsScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={SignedInspectionsScreen} />

            <Stack.Screen name="CompaniesScreen"
                options={({ navigation, route }) => ({
                    headerTitle: props => <LogoTitle {...props} />,
                    headerRight: () => (
                        <Icon
                            as={Ionicons}
                            name="add"
                            color="white"
                            m={2}
                            onPress={() => navigation.navigate('CreateTransportingCompanyScreen')}
                        />
                    ),

                })}
                component={CompaniesScreen} />
            <Stack.Screen name="CreateTransportingCompanyScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={CreateTransportingCompanyScreen} />
            <Stack.Screen name="SignatureScreen"
                options={({ navigation }) => ({
                    // headerLeft: () => leftIcon(navigation, 'menu'),
                    headerTitle: props => <LogoTitle {...props} />,
                    // headerRight: () => <Box />,
                })}
                component={SignatureScreen} />
        </Stack.Navigator>
    );
}

export const SettingsStack = ({ navigation, route }) => {
    return (
        <Stack.Navigator
            screenOptions={{ ...navigationOptions }}>
            <Stack.Screen name="SettingsScreen"
                options={({ navigation }) => ({
                    headerLeft: () => leftIcon(navigation, 'menu'),
                    headerTitle: props => <LogoTitle {...props} />
                })}
                component={SettingsScreen} />
        </Stack.Navigator>
    );
}

export const PendingRequestStack = ({ navigation, route }) => {
    return (
        <Stack.Navigator
            screenOptions={{ ...navigationOptions }}>
            <Stack.Screen name="PendingScreen"
                options={({ navigation }) => ({
                    headerLeft: () => leftIcon(navigation, 'menu'),
                    headerTitle: props => <LogoTitle {...props} />
                })}
                component={PendingScreen} />
            <Stack.Screen name="PendingImagesScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />,
                }}
                component={PendingImagesScreen} />
        </Stack.Navigator>
    );
}



export default HomeStack;