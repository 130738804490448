import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { ActivityIndicator, Alert, Dimensions, Linking, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
    Box,
    Heading,
    Icon,
    AspectRatio,
    Image,
    Text,
    Center,
    HStack,
    Stack,
    NativeBaseProvider,
    FormControl,
    Input,
    WarningOutlineIcon,
    Spacer,
    Button,
    ChevronRightIcon,
    VStack,
    FlatList,
    Actionsheet,
    useDisclose,
    Pressable,
    Progress,
    View,
    ScrollView
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { UserContext } from '../../stores/Store';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { collection, getDocs, getFirestore, query, Timestamp, updateDoc, doc, where } from "firebase/firestore";
import * as MediaLibrary from 'expo-media-library';
import * as VideoThumbnails from 'expo-video-thumbnails';
import { Video, AVPlaybackStatus } from 'expo-av';
import * as ImagePicker from 'expo-image-picker';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, } from '@firebase/storage';
import HtmlForm from '../../html/htmlForm';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import { Asset } from 'expo-asset';
import { manipulateAsync } from 'expo-image-manipulator';

var moment = require('moment');
const { width, height } = Dimensions.get('window');


export default function InspectionsScreen(props) {
    const { navigation } = props;
    const [user] = React.useContext(UserContext);
    const firestore = getFirestore();
    const [data, setData] = React.useState([]);
    const [working, setWorking] = React.useState(false);
    const { isOpen, onOpen, onClose } = useDisclose();
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [showVideoPicker, setShowVideoPicker] = React.useState(false)
    const [showForm, setShowForm] = React.useState(false)
    const [isUploading, setIsUploading] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [selected_video, setSelectedVideo] = React.useState(null);
    const storage = getStorage();
    const [imagesForm, setImagesForm] = React.useState([]);
    const [sections, setSections] = React.useState();

    const copyFromAssets = async (asset) => {
        try {
            await Asset.loadAsync(asset);
            const { localUri } = Asset.fromModule(asset);

            return localUri;
        } catch (error) {
            console.log(error);
            throw err;
        }
    };


    const processLocalImageIOS = async (imageUri) => {
        try {
            const uriParts = imageUri.split(".");
            const formatPart = uriParts[uriParts.length - 1];
            let format;

            if (formatPart.includes("png")) {
                format = "png";
            } else if (formatPart.includes("jpg") || formatPart.includes("jpeg")) {
                format = "jpeg";
            }

            const { base64 } = await manipulateAsync(
                imageUri,
                [],
                { format: format || "png", base64: true }
            );

            return `data:image/${format};base64,${base64}`;
        } catch (error) {
            console.log(error);
            throw error
        }
    };

    const htmlContent = async () => {
        try {
            const asset = require('../../assets/images/header_fondo.png');
            let src;
            src = await copyFromAssets(asset);
            // if (Platform.OS === 'ios') {
                src = await processLocalImageIOS(src);
            // }
            console.log(src);
            return `<img style="width:50px;height:50px;" alt="src" src=${src} />`
        } catch (error) {
            console.log(error);
        }
    }

    const getData = async () => {
        setWorking(true)
        var db = firestore;
        const q = query(collection(db, "forms"), where('complete', "==", true));
        const data = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // if (doc.data().complete === false || doc.data().complete === undefined) {
            data.push({
                ref: doc.id,
                data: doc.data()
            })
            // }

        });
        setData(data)
    }

    const getFormSections = async () => {
        var db = firestore;
        const q = query(collection(db, "form_sections"));
        const data = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push(doc.data())

        });
        setSections(data);
    }

    React.useEffect(() => {
        const unsuscribe = navigation.addListener('focus', () => {
            getData()
                .then(() => {
                    getFormSections();
                    setWorking(false)
                })
                .catch((error) => {
                    console.log(error);
                    setWorking(false)
                })
        });

        return unsuscribe;
    }, [navigation]);


    async function storeVideo(uri) {
        let filename = uri.split('/').pop();

        setIsUploading(true);
        await fetch(uri)
            .then((response) => {
                response.blob()
                    .then((blob) => {
                        if (blob) {
                            const refImage = ref(storage, 'security_camera_video/' + filename)

                            uploadBytesResumable(refImage, blob)
                                .on('state_changed',
                                    snapshot => {
                                        const progress = (
                                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                                        console.log(`Progress: ${progress}%`);
                                        setProgress(progress)
                                    },
                                    error => {
                                        console.log(error.code);
                                        setIsUploading(false);
                                        setProgress(0);
                                    },
                                    async () => {
                                        const refDoc = doc(firestore, "forms", selectedItem.ref);
                                        updateDoc(refDoc, {
                                            video: 'security_camera_video/' + filename
                                        })
                                            .catch((error) => {
                                                console.log(error);
                                                setIsUploading(false);
                                            })
                                            .then(() => {
                                                setIsUploading(false);
                                                setShowVideoPicker(false)
                                                setSelectedVideo(null);
                                                setProgress(0);
                                                getData()
                                                    .then(() => {
                                                        setWorking(false)
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        setWorking(false)
                                                    })
                                            })
                                    })

                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        setIsUploading(false);
                    })
            })
            .catch((error) => {
                console.log(error);
                setIsUploading(false);
            })
    }

    const getMedia = async () => {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
            alert('Sorry, we need camera roll permissions to make this work!');
        } else {
            pickImage()
        }
    }

    const pickImage = async () => {
        setShowVideoPicker(true);
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Videos,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 0.5,
        });



        if (!result.cancelled) {
            let filename = result.uri.split('/').pop();
            result.ref = filename;
            setSelectedVideo(result); console.log(result);
            // const uri = await generateThumbnail(result.uri)
            // setvideoThumbnail(uri);

        }
    };


    const renderIsAproved = (data) => {
        if (data.is_aproved) {
            if (checkAprovationLevel(data) === 0) {
                return (
                    <Text
                        _dark={{
                            color: "green.50",
                        }}
                        color="green.800"
                        bold
                        alignSelf="flex-end"
                    >
                        Aprobada
                    </Text>
                )
            }
            if (data.supervisor === undefined) {
                return (
                    <Text maxW={40} numberOfLines={2} ellipsizeMode="tail" style={{ color: "orange" }}>Pendiente de aprobación</Text>
                )
            }
            if (data.external_commerce_resp === undefined) {
                return (
                    <Text maxW={40} numberOfLines={2} ellipsizeMode="tail" style={{ color: "orange" }}>Pendiente de aprobación</Text>
                )
            }
            if (data.patrimonial_security_resp === undefined) {
                return (
                    <Text maxW={40} numberOfLines={2} ellipsizeMode="tail" style={{ color: "orange" }} >Pendiente de aprobación</Text>
                )
            }
            return (
                <Text
                    _dark={{
                        color: "green.50",
                    }}
                    color="green.800"
                    bold
                    alignSelf="flex-end"
                >
                    Aprobada
                </Text>
            )
        } else {
            return (
                <Text
                    _dark={{
                        color: "red.50",
                    }}
                    color="red.800"
                    alignSelf="flex-end"
                >
                    No aprobada
                </Text>
            )
        }
    }

    const renderHasVideo = (data) => {
        if (data.video) {
            return (
                <Text
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    color="coolGray.600"
                    _dark={{
                        color: "warmGray.200",
                    }}
                >
                    {data.video}
                </Text>
            )
        } else {
            return (
                <Text
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    color="coolGray.600"
                    _dark={{
                        color: "warmGray.200",
                    }}
                >
                    Videograbación no cargada
                </Text>
            )
        }
    }

    const checkAprovationLevel = (form) => {
        let level = 0;
        if (form.supervisor === undefined) {
            return 1
        }
        if (form.external_commerce_resp === undefined) {
            return 2
        }
        if (form.patrimonial_security_resp === undefined) {
            return 3
        }
        return level;
    };

    const userCanSign = (data) => {
        const { email } = user;
        const level = checkAprovationLevel(data)
        if (!data.is_aproved) return false;
        switch (email) {
            case 'supervisor@virbac.com':
                if (level >= 1) return true;
                return false;
            case 'comercioexterior@virbac.com':
                if (level >= 2) return true;
                return false;
            case 'comercioexteriorimport@virbac.com':
                if (level >= 2) return true;
                return false;
            case 'seguridadpatrimonial@virbac.com':
                if (level >= 3) return true;
                return false;

            default:
                return false;
        }

    }



    if (working) {
        return <PreLoader />;
    }

    if (isUploading) {
        return (
            <Center flex={1}>
                <Box w="90%">
                    <Center mb="10">
                        <Heading size="md"> Subiendo video</Heading>
                    </Center>
                    <Progress value={progress} mx="4" />
                </Box>
            </Center>
        );
    }

    if (showVideoPicker) {
        if (Platform.OS === 'web') {
            return (
                <Center flex={1}>
                    <SafeAreaView style={{ flex: 1, padding: 10 }}>
                        <Box
                            rounded="lg"
                            overflow="hidden"
                            width={{
                                base: 'sm',
                                sm: 'xl',
                                md: 768,
                                lg: 992,
                                xl: 1280,
                            }}
                            height="full"
                            shadow={1}
                            _light={{ backgroundColor: 'gray.50' }}
                            _dark={{ backgroundColor: 'gray.700' }}
                        >
                            <Stack p="4" space={3}>
                                <HStack>
                                    <Icon as={Ionicons} name="arrow-back-outline" onPress={() => setShowVideoPicker(false)} />
                                    <Spacer />
                                    <Stack space={2}>
                                        <Heading size="md" ml="-1">
                                            {`Video de la cámara de seguridad`}
                                        </Heading>
                                    </Stack>


                                </HStack>
                                <Spacer />
                                <Box>
                                    {selected_video ?
                                        (
                                            <Center>
                                                <Video
                                                    onError={(error) => console.log(error)}
                                                    style={{ height: 500, width: width - 50 }}
                                                    source={{
                                                        uri: selected_video.uri,
                                                    }}
                                                    useNativeControls
                                                    resizeMode="contain"
                                                    isLooping
                                                // onPlaybackStatusUpdate={status => setStatus(() => status)}
                                                />
                                                <Icon
                                                    onPress={() => {
                                                        setSelectedVideo(null)
                                                    }}
                                                    position="absolute"
                                                    top="2%"
                                                    right="5%"
                                                    backgroundColor="#ffffff30"
                                                    as={<MaterialIcons name="remove-circle-outline" />} color="black" size={8} >
                                                </Icon>
                                            </Center>
                                        )
                                        :
                                        <Center>
                                            <Button
                                                rightIcon={<Icon as={<Ionicons name="md-film" />} color="white" />}
                                                colorScheme={"info"}
                                                size="md"
                                                onPress={() => pickImage()}
                                            >
                                                Seleccionar video
                                            </Button>
                                        </Center>
                                    }

                                </Box>
                                <Box>
                                    <HStack>
                                        <Spacer />
                                        <Button
                                            isLoading={isUploading}
                                            disabled={!selected_video}
                                            rightIcon={<Icon as={Ionicons} name="save" color="white" />}
                                            colorScheme={selected_video ? "info" : "gray"}
                                            size="md"
                                            onPress={() => {
                                                storeVideo(selected_video.uri)
                                                    .catch((error) => {
                                                        console.log(error);
                                                        setIsUploading(false);
                                                    })
                                            }}
                                        >
                                            Guardar
                                        </Button>
                                    </HStack>

                                </Box>
                            </Stack>

                        </Box>
                    </SafeAreaView>
                </Center>
            )
        }
        return (
            <Center flex={1}>
                <SafeAreaView style={{ flex: 1, padding: 10 }}>
                    <Box
                        rounded="lg"
                        overflow="hidden"
                        width={{
                            base: 'sm',
                            sm: 'xl',
                            md: 768,
                            lg: 992,
                            xl: 1280,
                        }}
                        height="full"
                        shadow={1}
                        _light={{ backgroundColor: 'gray.50' }}
                        _dark={{ backgroundColor: 'gray.700' }}
                    >
                        <Stack p="4" space={3}>
                            <HStack>
                                <Icon as={Ionicons} name="arrow-back-outline" onPress={() => setShowVideoPicker(false)} />
                                <Spacer />
                                <Stack space={2}>
                                    <Heading size="md" ml="-1">
                                        {`Video de la cámara de seguridad`}
                                    </Heading>
                                </Stack>


                            </HStack>
                            <Spacer />
                            <Box>
                                {selected_video ?
                                    (
                                        <Box>
                                            <Video
                                                onError={(error) => console.log(error)}
                                                style={{ height: 500, width: width - 50 }}
                                                source={{
                                                    uri: selected_video.uri,
                                                }}
                                                useNativeControls
                                                resizeMode="contain"
                                                isLooping
                                            // onPlaybackStatusUpdate={status => setStatus(() => status)}
                                            />
                                            <Icon
                                                onPress={() => {
                                                    setSelectedVideo(null)
                                                }}
                                                position="absolute"
                                                top="2%"
                                                right="5%"
                                                backgroundColor="#ffffff30"
                                                as={<MaterialIcons name="remove-circle-outline" />} color="black" size={8} >
                                            </Icon>
                                        </Box>
                                    )
                                    :
                                    <Box
                                        h={500}
                                        w={width - 50}
                                    >
                                        <Button

                                            rightIcon={<Icon as={<Ionicons name="md-film" />} color="white" />}
                                            colorScheme={"info"}
                                            size="md"
                                            onPress={() => pickImage()}
                                        >
                                            Seleccionar video
                                        </Button>
                                    </Box>
                                }

                            </Box>
                            <Box>
                                <HStack>
                                    <Spacer />
                                    <Button
                                        isLoading={isUploading}
                                        disabled={!selected_video}
                                        rightIcon={<Icon as={Ionicons} name="save" color="white" />}
                                        colorScheme={selected_video ? "info" : "gray"}
                                        size="md"
                                        onPress={() => {
                                            storeVideo(selected_video.uri)
                                                .catch((error) => {
                                                    console.log(error);
                                                    setIsUploading(false);
                                                })
                                        }}
                                    >
                                        Guardar
                                    </Button>
                                </HStack>

                            </Box>
                        </Stack>

                    </Box>
                </SafeAreaView>
            </Center>
        )
    }

    const getImageFirebase = (url) => {
        if(url === undefined) return ''
        const refImage = ref(storage, url)

        return getDownloadURL(refImage)
    }

    const renderFormQuestion = (questionText, isCritic, response, observations) => {
        if (response !== undefined) {
            if (observations !== undefined) {
                return (
                    <VStack space={2}>
                        <HStack>
                            <Text
                                maxW="80%"
                                numberOfLines={3}
                                ellipsizeMode="tail"
                                color={isCritic ? "red.600" : "coolGray.600"}
                                _dark={{
                                    color: "warmGray.200",
                                }}
                            >
                                {questionText}
                            </Text>
                            <Spacer />
                            <Text
                                numberOfLines={3}
                                ellipsizeMode="tail"
                                color={"coolGray.600"}
                                _dark={{
                                    color: "warmGray.200",
                                }}
                            >{response ? "SI" : "NO"}
                            </Text>
                        </HStack>
                        <Text
                            numberOfLines={3}
                            ellipsizeMode="tail"
                            color="coolGray.600"
                            _dark={{
                                color: "warmGray.200",
                            }}
                        >
                            Observaciones: {observations}
                        </Text>
                    </VStack>

                )
            }
            return (
                <HStack>
                    <Text
                        maxW="80%"
                        numberOfLines={3}
                        ellipsizeMode="tail"
                        color={isCritic ? "red.600" : "coolGray.600"}
                        _dark={{
                            color: "warmGray.200",
                        }}
                    >
                        {questionText}
                    </Text>
                    <Spacer />
                    <Text
                        numberOfLines={3}
                        ellipsizeMode="tail"
                        color={"coolGray.600"}
                        _dark={{
                            color: "warmGray.200",
                        }}
                    >{response ? "SI" : "NO"}
                    </Text>
                </HStack>

            )
        }
        return null;
    }

    const renderFormSection = (item) => {
        const { data, order } = item;
        var values = Object.keys(data).map(function (key) { return data[key]; });
        values.sort((a, b) => a.order > b.order);
        const section = sections.find((element) => element.order === order)
        return (
            <VStack marginY={2}>
                <Text
                    marginY={2}
                    numberOfLines={3}
                    ellipsizeMode="tail"
                    color="coolGray.600"
                    _dark={{
                        color: "warmGray.200",
                    }}
                >
                    {`${section.order}) ${section.name}`}
                </Text>
                <FlatList
                    data={values}
                    keyExtractor={(item) => item.order}
                    renderItem={({ item }) => renderFormQuestion(
                        item.q,
                        item.critic ? item.critic : false,
                        item.value,
                        item.observations
                    )}
                />
            </VStack>)
    }

    const renderVehiclesImages = (images) => {
        return (
            <FlatList
                data={images}
                ListHeaderComponent={
                    <Text>Imagenes vehiculo</Text>
                }
                renderItem={({ item }) => {
                    if (item) {
                        return (
                            <Image
                                source={{ uri: item }}
                                h="150"
                                w="250"
                                resizeMode="stretch"
                                alt={item}
                            />
                        )
                    } else {
                        return null;
                    }

                }}
                keyExtractor={(item, index) => index}
            />
        )

    }

    const RenderForm = () => {
        const { data } = selectedItem
        const [
            inspector_signature,
            supervisor_signature,
            patrimonial_security_resp_signature,
            external_commerce_resp_signature,
            driver_circulation_card_image,
            driver_licence_image,
            plate_number_image,
            lock_image,
            pallet_image,
            // vechicle images can be up to 10
            vehicles_image1,
            vehicles_image2,
            vehicles_image3,
            vehicles_image4,
            vehicles_image5,
            vehicles_image6,
            vehicles_image7,
            vehicles_image8,
            vehicles_image9,
            vehicles_image10,
        ] = imagesForm;
        const signatures = [
            inspector_signature,
            supervisor_signature,
            patrimonial_security_resp_signature,
            external_commerce_resp_signature,
        ]

        const vehicles_images = [
            vehicles_image1,
            vehicles_image2,
            vehicles_image3,
            vehicles_image4,
            vehicles_image5,
            vehicles_image6,
            vehicles_image7,
            vehicles_image8,
            vehicles_image9,
            vehicles_image10,
        ];
        const { questions } = data;
        var values = Object.keys(questions).map(function (key) { return questions[key]; });
        values.sort((a, b) => a.order > b.order);
        return (
            <Center flex={1}>
                <SafeAreaView style={{ flex: 1, padding: 10 }}>
                    <Box
                        rounded="lg"
                        overflow="hidden"
                        width={{
                            base: 'sm',
                            sm: 'xl',
                            md: 768,
                            lg: 992,
                            xl: 1280,
                        }}
                        height="full"
                        shadow={1}
                        _light={{ backgroundColor: 'gray.50' }}
                        _dark={{ backgroundColor: 'gray.700' }}
                    >
                        <HStack m="2">
                            <Icon as={Ionicons} name="arrow-back-outline" onPress={() => setShowForm(false)} />
                            <Spacer />
                            <Stack space={2}>
                                <Heading size="md" ml="-1">
                                    {`Formulario`}
                                </Heading>
                            </Stack>
                        </HStack>
                        <ScrollView>
                            <Stack p="4" space={3}>

                                <Box
                                    borderWidth="1"
                                    _dark={{
                                        borderColor: "gray.600",
                                    }}
                                    borderColor="coolGray.200"
                                    pl="4"
                                    pr="5"
                                    py="2"
                                    mb="2"
                                    borderRadius="5"

                                >
                                    <HStack space={1} justifyContent="space-between">
                                        <VStack maxW="50%">
                                            <Text
                                                _dark={{
                                                    color: "warmGray.50",
                                                }}
                                                color="coolGray.800"
                                                bold
                                            >
                                                Ispector: {data.inspector}
                                            </Text>
                                            <Text
                                                color="coolGray.600"
                                                _dark={{
                                                    color: "warmGray.200",
                                                }}
                                            >
                                                Chofer: {data.driver}
                                            </Text>
                                            <Text
                                                color="coolGray.600"
                                                _dark={{
                                                    color: "warmGray.200",
                                                }}
                                            >
                                                Licencia de chofer: {data.driver_licence_number}
                                            </Text>
                                            {
                                                driver_licence_image &&
                                                <Image
                                                    source={{ uri: driver_licence_image }}
                                                    h="150"
                                                    w="250"
                                                    resizeMode="stretch"
                                                    alt="driver_licence_image"
                                                />
                                            }
                                            <Text
                                                numberOfLines={3}
                                                ellipsizeMode="tail"
                                                color="coolGray.600"
                                                _dark={{
                                                    color: "warmGray.200",
                                                }}
                                            >
                                                {data.company}
                                            </Text>

                                        </VStack>
                                        <Spacer />
                                        <VStack>
                                            <Text
                                                fontSize="xs"
                                                _dark={{
                                                    color: "warmGray.50",
                                                }}
                                                color="coolGray.800"
                                                alignSelf="flex-end"
                                            >
                                                {moment(new Timestamp(data.startAt?.seconds, data.startAt?.nanoseconds).toDate()).format('DD/MM/YYYY')}
                                            </Text>
                                            <Text
                                                fontSize="xs"
                                                _dark={{
                                                    color: "warmGray.50",
                                                }}
                                                color="coolGray.800"
                                                alignSelf="flex-end"
                                            >
                                                Inicio: {moment(new Timestamp(data.startAt?.seconds, data.startAt?.nanoseconds).toDate()).format('hh:mm A')}
                                            </Text>
                                            <Text
                                                fontSize="xs"
                                                _dark={{
                                                    color: "warmGray.50",
                                                }}
                                                color="coolGray.800"
                                                alignSelf="flex-end"
                                            >
                                                Fin: {moment(new Timestamp(data.endAt?.seconds, data.endAt?.nanoseconds).toDate()).format('hh:mm A')}
                                            </Text>
                                            <Text
                                                fontSize="xs"
                                                _dark={{
                                                    color: "warmGray.50",
                                                }}
                                                color="coolGray.800"
                                                alignSelf="flex-end"
                                            >
                                                <Icon size="xs" as={Ionicons} name={data.isArriving ? "arrow-up-outline" : "arrow-down-outline"} />
                                                {data.isArriving ? 'Exportación' : 'Importación'}
                                            </Text>
                                            <View>
                                                {renderIsAproved(data)}
                                            </View>
                                        </VStack>
                                    </HStack>
                                    <VStack>
                                        {
                                            driver_circulation_card_image &&
                                            <Image
                                                source={{ uri: driver_circulation_card_image }}
                                                h="150"
                                                w="250"
                                                resizeMode="stretch"
                                                alt="driver_circulation_card_image"
                                            />
                                        }
                                        <Text
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            Tarjeta de circulación valida:
                                            <Icon as={Ionicons} name={data.is_circulation_card_valid ? "checkmark" : "close"} />
                                        </Text>
                                        <Text
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            Número de sello: {data.stampNumber}
                                        </Text>
                                        <Text
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            Placas: {data.plate_number}
                                        </Text>
                                        {
                                            plate_number_image &&
                                            <Image
                                                source={{ uri: plate_number_image }}
                                                h="150"
                                                w="250"
                                                resizeMode="stretch"
                                                alt="plate_number_image"
                                            />
                                        }
                                        <Text
                                            numberOfLines={3}
                                            ellipsizeMode="tail"
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            Cumple el Conductor con lo declarado en la Notificación:
                                            <Icon as={Ionicons} name={data.isDeclarationValid ? "checkmark" : "close"} />
                                        </Text>

                                        {data.declarationObservations !== '' && <Text
                                            numberOfLines={3}
                                            ellipsizeMode="tail"
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            {` Observaciones: ${data.declarationObservations}`}
                                        </Text>}
                                        <Text
                                            numberOfLines={3}
                                            ellipsizeMode="tail"
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            Tipo de vehiculo: {data.vehicle}
                                        </Text>
                                        <HStack>
                                            <Text
                                                numberOfLines={3}
                                                ellipsizeMode="tail"
                                                color="coolGray.600"
                                                _dark={{
                                                    color: "warmGray.200",
                                                }}
                                            >
                                                Medidas
                                            </Text>
                                            <Spacer />
                                            <Stack>
                                                <Text
                                                    numberOfLines={3}
                                                    ellipsizeMode="tail"
                                                    color="coolGray.600"
                                                    _dark={{
                                                        color: "warmGray.200",
                                                    }}
                                                >
                                                    Internas
                                                </Text>
                                                <VStack>
                                                    <Text
                                                        numberOfLines={3}
                                                        ellipsizeMode="tail"
                                                        color="coolGray.600"
                                                        _dark={{
                                                            color: "warmGray.200",
                                                        }}
                                                    >
                                                        Alto: {data.InternHeight}
                                                    </Text>
                                                    <Text
                                                        numberOfLines={3}
                                                        ellipsizeMode="tail"
                                                        color="coolGray.600"
                                                        _dark={{
                                                            color: "warmGray.200",
                                                        }}
                                                    >
                                                        Ancho: {data.InternWidth}
                                                    </Text>
                                                    <Text
                                                        numberOfLines={3}
                                                        ellipsizeMode="tail"
                                                        color="coolGray.600"
                                                        _dark={{
                                                            color: "warmGray.200",
                                                        }}
                                                    >
                                                        Largo: {data.InternLenght}
                                                    </Text>
                                                </VStack>
                                            </Stack>
                                            <Spacer />
                                            <Stack>
                                                <Text
                                                    numberOfLines={3}
                                                    ellipsizeMode="tail"
                                                    color="coolGray.600"
                                                    _dark={{
                                                        color: "warmGray.200",
                                                    }}
                                                >
                                                    Externas
                                                </Text>
                                                <VStack>
                                                    <Text
                                                        numberOfLines={3}
                                                        ellipsizeMode="tail"
                                                        color="coolGray.600"
                                                        _dark={{
                                                            color: "warmGray.200",
                                                        }}
                                                    >
                                                        Alto: {data.ExternHeight}
                                                    </Text>
                                                    <Text
                                                        numberOfLines={3}
                                                        ellipsizeMode="tail"
                                                        color="coolGray.600"
                                                        _dark={{
                                                            color: "warmGray.200",
                                                        }}
                                                    >
                                                        Ancho: {data.ExternWidth}
                                                    </Text>
                                                    <Text
                                                        numberOfLines={3}
                                                        ellipsizeMode="tail"
                                                        color="coolGray.600"
                                                        _dark={{
                                                            color: "warmGray.200",
                                                        }}
                                                    >
                                                        Largo: {data.ExternLenght}
                                                    </Text>
                                                </VStack>
                                            </Stack>
                                        </HStack>
                                    </VStack>
                                    {renderVehiclesImages(vehicles_images)}
                                    <FlatList
                                        data={values}
                                        renderItem={({ item }) => renderFormSection(item)}
                                        keyExtractor={(item) => item.order}
                                    />
                                    <VStack>
                                        <Text
                                            numberOfLines={3}
                                            ellipsizeMode="tail"
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            Imagen Tarima
                                        </Text>
                                        {
                                            pallet_image !== undefined &&
                                            <Image
                                                source={{ uri: pallet_image }}
                                                h="150"
                                                w="250"
                                                resizeMode="stretch"
                                                alt="pallet_image"
                                            />
                                        }
                                        <Text
                                            numberOfLines={3}
                                            ellipsizeMode="tail"
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            Imagen candado
                                        </Text>
                                        {
                                            lock_image !== undefined &&
                                            <Image
                                                source={{ uri: lock_image }}
                                                h="150"
                                                w="250"
                                                resizeMode="stretch"
                                                alt="lock_image"
                                            />
                                        }

                                    </VStack>
                                    <VStack>
                                        <Text
                                            numberOfLines={1}
                                            ellipsizeMode="tail"
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            Firma supervisor
                                        </Text>
                                        {
                                            supervisor_signature !== undefined ?
                                                <Image
                                                    source={{ uri: supervisor_signature }}
                                                    h="150"
                                                    w="250"
                                                    resizeMode="stretch"
                                                    alt="supervisor_signature"
                                                />
                                                :
                                                <Text
                                                    numberOfLines={1}
                                                    ellipsizeMode="tail"
                                                    color="coolGray.600"
                                                    _dark={{
                                                        color: "warmGray.200",
                                                    }}
                                                >
                                                    No aplica
                                                </Text>
                                        }
                                        <Text
                                            numberOfLines={1}
                                            ellipsizeMode="tail"
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            Firma Resp. de comercio exterior
                                        </Text>
                                        {
                                            external_commerce_resp_signature !== undefined ?
                                                <Image
                                                    source={{ uri: external_commerce_resp_signature }}
                                                    h="150"
                                                    w="250"
                                                    resizeMode="stretch"
                                                    alt="external_commerce_resp"
                                                />
                                                :
                                                <Text
                                                    numberOfLines={1}
                                                    ellipsizeMode="tail"
                                                    color="coolGray.600"
                                                    _dark={{
                                                        color: "warmGray.200",
                                                    }}
                                                >
                                                    No aplica
                                                </Text>
                                        }
                                        <Text
                                            numberOfLines={1}
                                            ellipsizeMode="tail"
                                            color="coolGray.600"
                                            _dark={{
                                                color: "warmGray.200",
                                            }}
                                        >
                                            Firma Resp. de seguridad patrimonial
                                        </Text>
                                        {
                                            patrimonial_security_resp_signature !== undefined ?
                                                <Image
                                                    source={{ uri: patrimonial_security_resp_signature }}
                                                    h="150"
                                                    w="250"
                                                    resizeMode="stretch"
                                                    alt="patrimonial_security_resp"
                                                />
                                                :
                                                <Text
                                                    numberOfLines={1}
                                                    ellipsizeMode="tail"
                                                    color="coolGray.600"
                                                    _dark={{
                                                        color: "warmGray.200",
                                                    }}
                                                >
                                                    No aplica
                                                </Text>
                                        }

                                    </VStack>
                                </Box>
                                <Spacer />
                                {userCanSign(data) && <Box>
                                    <HStack>
                                        <Spacer />
                                        <Button
                                            disabled={userCanSign(data)}
                                            rightIcon={<Icon as={Ionicons} name="pencil" color="white" />}
                                            colorScheme={userCanSign(data) ? "info" : "gray"}
                                            size="md"
                                            onPress={() => {
                                                const level = checkAprovationLevel(selectedItem.data);
                                                navigation.navigate('SignatureScreen', {
                                                    item: selectedItem, level
                                                })
                                            }}
                                        >
                                            Firmar
                                        </Button>
                                    </HStack>

                                </Box>}
                            </Stack>
                        </ScrollView>
                    </Box>
                </SafeAreaView>
            </Center>
        )
    }

    if (showForm) {
        return <RenderForm />
    }

    return (
        <Center flex={1}>
            <SafeAreaView style={{ flex: 1, padding: 10 }}>
                <Box
                    rounded="lg"
                    overflow="hidden"
                    width={{
                        base: 'sm',
                        sm: 'xl',
                        md: 768,
                        lg: 992,
                        xl: 1280,
                    }}
                    height="full"
                    shadow={1}
                    _light={{ backgroundColor: 'gray.50' }}
                    _dark={{ backgroundColor: 'gray.700' }}
                >
                    <Actionsheet isOpen={isOpen} onClose={onClose}>
                        <Actionsheet.Content>
                            <Box w="100%" h={60} px={4} justifyContent="center">
                                <Text
                                    fontSize="16"
                                    color="gray.500"
                                    _dark={{
                                        color: "gray.300",
                                    }}
                                >
                                    Acciones
                                </Text>
                            </Box>
                            {(selectedItem && selectedItem.data.video === undefined) && <Actionsheet.Item onPress={() => {
                                getMedia()
                                onClose()
                            }}>Cargar Videograbación</Actionsheet.Item>}
                            {
                                (selectedItem && userCanSign(selectedItem.data)) &&
                                <Actionsheet.Item onPress={() => {
                                    const level = checkAprovationLevel(selectedItem.data);
                                    navigation.navigate('SignatureScreen', {
                                        item: selectedItem, level
                                    })
                                    onClose()
                                }}>Firmar</Actionsheet.Item>
                            }
                            {selectedItem && (
                                <Actionsheet.Item onPress={() => {
                                    onClose()
                                    setWorking(true);
                                    const promises = [
                                        getImageFirebase(selectedItem.data.inspector_signature),
                                        getImageFirebase(selectedItem.data.supervisor_signature),
                                        getImageFirebase(selectedItem.data.patrimonial_security_resp_signature),
                                        getImageFirebase(selectedItem.data.external_commerce_resp_signature),
                                        getImageFirebase(selectedItem.data.driver_circulation_card_image),
                                        getImageFirebase(selectedItem.data.driver_licence_image),
                                        getImageFirebase(selectedItem.data.plate_number_image),
                                        getImageFirebase(selectedItem.data.lock_image),
                                        getImageFirebase(selectedItem.data.pallet_image)];
                                    selectedItem.data.vehicles_images.map((element) => {
                                        promises.push(getImageFirebase(element))
                                    });
                                    Promise.all(promises) 
                                        .then((responses) => {
                                            setImagesForm(responses)
                                            setWorking(false)
                                            setShowForm(true);
                                            // const content = render(renderForm())
                                            // Print.printToFileAsync({ html: content })
                                            //     .then((response) => {
                                            //         console.log(response);
                                            //         Sharing.shareAsync(response.uri)
                                            //         onClose()
                                            //     })
                                            //     .catch((error) => {
                                            //         console.log(error);
                                            //     })


                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            setWorking(false)
                                            setShowForm(true);
                                        })


                                }}>Ver formulario</Actionsheet.Item>)}
                            <Actionsheet.Item onPress={() => {
                                setWorking(true);
                                const promises = [
                                    getImageFirebase(selectedItem.data.inspector_signature),
                                    getImageFirebase(selectedItem.data.supervisor_signature),
                                    getImageFirebase(selectedItem.data.patrimonial_security_resp_signature),
                                    getImageFirebase(selectedItem.data.external_commerce_resp_signature),
                                    getImageFirebase(selectedItem.data.driver_circulation_card_image),
                                    getImageFirebase(selectedItem.data.driver_licence_image),
                                    getImageFirebase(selectedItem.data.plate_number_image),
                                    getImageFirebase(selectedItem.data.lock_image),
                                    getImageFirebase(selectedItem.data.pallet_image)];
                                selectedItem.data.vehicles_images.map((element) => {
                                    promises.push(getImageFirebase(element))
                                });
                                selectedItem.data.vehicles_images.map((element) => {
                                    promises.push(getImageFirebase(element))
                                });
                                Promise.all(promises)
                                    .then((responses) => {
                                        const [
                                            inspector_signature,
                                            supervisor_signature,
                                            patrimonial_security_resp_signature,
                                            external_commerce_resp_signature,
                                            driver_circulation_card_image,
                                            driver_licence_image,
                                            plate_number_image,
                                            lock_image,
                                            pallet_image,
                                            // vechicle images can be up to 10
                                            vehicles_image1,
                                            vehicles_image2,
                                            vehicles_image3,
                                            vehicles_image4,
                                            vehicles_image5,
                                            vehicles_image6,
                                            vehicles_image7,
                                            vehicles_image8,
                                            vehicles_image9,
                                            vehicles_image10,
                                        ] = responses;
                                        const signatures = [
                                            inspector_signature,
                                            supervisor_signature,
                                            patrimonial_security_resp_signature,
                                            external_commerce_resp_signature,
                                        ]
                                        const vehicles_images = [
                                            vehicles_image1,
                                            vehicles_image2,
                                            vehicles_image3,
                                            vehicles_image4,
                                            vehicles_image5,
                                            vehicles_image6,
                                            vehicles_image7,
                                            vehicles_image8,
                                            vehicles_image9,
                                            vehicles_image10,
                                        ];
                                        const formImages = [
                                            driver_circulation_card_image,
                                            driver_licence_image,
                                            plate_number_image,
                                            lock_image,
                                            pallet_image,
                                        ];
                                        setWorking(false)
                                        Sharing.isAvailableAsync()
                                            .then((response) => {
                                                if (response) {
                                                    htmlContent()
                                                        .then((image) => {
                                                            const html = HtmlForm(selectedItem, image, sections, signatures, vehicles_images, formImages);
                                                            onClose()
                                                            Print.printToFileAsync({ html })
                                                                .then((response) => {
                                                                    Sharing.shareAsync(response.uri)

                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                })
                                                        })
                                                }
                                            })

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        setWorking(false)
                                        setShowForm(true);
                                    })

                                // console.log(selectedItem);

                            }}>Enviar</Actionsheet.Item>
                            <Actionsheet.Item onPress={onClose}>Cancelar</Actionsheet.Item>
                        </Actionsheet.Content>
                    </Actionsheet>
                    <Stack p="4" space={3} mb="10">
                        <Stack space={2}>
                            <Heading size="md" ml="-1">
                                {`Inspecciones`}
                            </Heading>
                        </Stack>
                        <Spacer />
                        <FlatList
                            data={data}
                            keyExtractor={(item) => item.ref}
                            renderItem={({ item: { data, ref } }) => {
                                // if (!userCanSign(data))
                                //     return null;

                                return (
                                    <Box
                                        borderWidth="1"
                                        _dark={{
                                            borderColor: "gray.600",
                                        }}
                                        borderColor="coolGray.200"
                                        pl="4"
                                        pr="5"
                                        py="2"
                                        mb="2"
                                        borderRadius="5"

                                    >
                                        <HStack space={1} justifyContent="space-between">
                                            <VStack maxW="42%">
                                                <Text
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    bold
                                                >
                                                    Ispector: {data.inspector}
                                                </Text>
                                                <Text
                                                    color="coolGray.600"
                                                    _dark={{
                                                        color: "warmGray.200",
                                                    }}
                                                >
                                                    Chofer: {data.driver}
                                                </Text>
                                                <Text
                                                    numberOfLines={3}
                                                    ellipsizeMode="tail"
                                                    color="coolGray.600"
                                                    _dark={{
                                                        color: "warmGray.200",
                                                    }}
                                                >
                                                    {data.company}
                                                </Text>
                                                {renderHasVideo(data)}
                                            </VStack>
                                            <Spacer />
                                            <VStack maxW="42%">
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    {moment(new Timestamp(data.startAt?.seconds, data.startAt?.nanoseconds).toDate()).format('DD/MM/YYYY')}
                                                </Text>
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    Inicio: {moment(new Timestamp(data.startAt?.seconds, data.startAt?.nanoseconds).toDate()).format('hh:mm A')}
                                                </Text>
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    Fin: {moment(new Timestamp(data.endAt?.seconds, data.endAt?.nanoseconds).toDate()).format('hh:mm A')}
                                                </Text>
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    <Icon size="xs" as={Ionicons} name={data.isArriving ? "arrow-up-outline" : "arrow-down-outline"} />
                                                    {data.isArriving ? 'Exportación' : 'Importación'}
                                                </Text>
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                   {data.vehicle}
                                                </Text>
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    Sello: {data.stampNumber}
                                                </Text>
                                                <Spacer />
                                                <View>
                                                    {renderIsAproved(data)}
                                                </View>
                                            </VStack>
                                            <Icon onPress={() => {
                                                setSelectedItem({ data, ref })
                                                onOpen()
                                            }} alignSelf="flex-start" as={Ionicons} name="menu" />
                                        </HStack>
                                    </Box>
                                )
                            }}
                            ListEmptyComponent={() => (
                                <Box
                                    borderWidth="1"
                                    _dark={{
                                        borderColor: "gray.600",
                                    }}
                                    borderColor="coolGray.200"
                                    pl="4"
                                    pr="5"
                                    py="2"
                                    mb="2"
                                    borderRadius="5"

                                >
                                    <HStack space={1} justifyContent="space-between">
                                        <VStack maxW="100%">
                                            <Text
                                                _dark={{
                                                    color: "warmGray.50",
                                                }}
                                                color="coolGray.800"
                                                bold
                                            >
                                                Sin información para mostrar
                                            </Text>

                                        </VStack>
                                    </HStack>
                                </Box>
                            )}
                        />
                    </Stack>

                </Box>
            </SafeAreaView>
        </Center>
    );
}