import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Icon,
  AspectRatio,
  Image,
  Text,
  Center,
  HStack,
  Stack,
  NativeBaseProvider,
  FormControl,
  Input,
  WarningOutlineIcon,
  Spacer,
  Button,
  ChevronRightIcon
} from 'native-base';
import { FormContext } from '../stores/Store.tsx';
import PreLoader from '../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';

var numeral = require('numeral');


export default function HomeScreen(props) {
  const { navigation } = props;
  const drawer = React.useRef(null);
  let [form, setForm] = React.useContext(FormContext);
  const [inspector, setInspector] = React.useState('');
  const [working, setWorking] = React.useState(false);

  const showToastWithGravity = (msg, x, y) => {
    ToastAndroid.showWithGravity(
      msg,
      ToastAndroid.SHORT,
      ToastAndroid.CENTER,
    );
  };

  const continueForm = () => {
    Alert.alert('Atención',
      'A partir de ese momento se hará la solicitud para el video de la grabación de las cámaras de seguridad',
      [
        { text: 'Cancelar' },
        {
          text: 'Ok', onPress: () => {
            setForm((prev) => {
              return {
                ...prev, ...{ inspector, startAt: new Date() }
              }
            });
            navigation.navigate('SelectVehicleScreen')
          }
        }
      ])
  }


  if (working) {
    return <PreLoader />;
  }

  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <Box
          rounded="lg"
          overflow="hidden"
          width={{
            base: 'xs',
            sm: 'xl',
            md: 768,
            lg: 992,
            xl: 1280,
          }}
          height="full"
          shadow={1}
          _light={{ backgroundColor: 'gray.50' }}
          _dark={{ backgroundColor: 'gray.700' }}
        >
          <Stack p="4" space={3}>
            <Stack space={2}>
              <Heading size="md" ml="-1">
                Comenzar nueva inspección
              </Heading>
            </Stack>
            <FormControl isRequired>
              <Stack mx="4">
                <FormControl.Label>Inspector</FormControl.Label>
                <Input value={inspector} onChangeText={(text) => setInspector(text)} placeholder="Nombre completo" />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  Atleast 6 characters are required.
                </FormControl.ErrorMessage>
              </Stack>
            </FormControl>
          </Stack>
          <Spacer />
          <Box>
            <HStack>
              <Spacer />
              <Button disabled={inspector === ''} rightIcon={<ChevronRightIcon color="white" />}
                colorScheme={inspector !== '' ? "info" : "gray"}
                size="md"
                onPress={() => continueForm()}
              >
                Comenzar inspección
              </Button>
            </HStack>

          </Box>
        </Box>
      </SafeAreaView>
    </Center>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(0,0,0,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  },
  contentContainer: {
    paddingTop: 30,
  },
  welcomeContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightText: {
    color: 'rgba(96,100,109, 0.8)',
  },
  codeHighlightContainer: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
  },
  tabBarInfoContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
      },
      android: {
        elevation: 20,
      },
    }),
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    paddingVertical: 20,
  },
  tabBarInfoText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    textAlign: 'center',
  },
  navigationFilename: {
    marginTop: 5,
  },
  helpContainer: {
    marginTop: 15,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
