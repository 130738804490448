import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { loggedValues, permissionsValues } from '../utils/enums';
import PreLoader from '../components/PreLoader';

export const LoggContext = createContext<loggedValues | any>(loggedValues.NOLOGGED);
export const PermissionContext = createContext<permissionsValues | any>(permissionsValues.REP);
export const UserContext = createContext({});
export const FormContext = createContext({});

function Store({ children }) {
  const [logged, setLogged] = useState<loggedValues>(loggedValues.NOLOGGED);
  const [permission, setPermission] = useState<permissionsValues>(permissionsValues.REP);
  const [working, setWorking] = useState(false);
  const [user, setUser] = useState({});
  const [form, setForm] = useState({ questions: {} });

  if (working) {
    return (
      <PreLoader />
    );
  }

  return (
    <LoggContext.Provider value={[logged, setLogged]} >
      <PermissionContext.Provider value={[permission, setPermission]} >
        {/** Provider suscribe a cambios del value a todos sus childrens,
        los children vuelven a renderizar cuando el value cambia* */}
        <UserContext.Provider value={[user, setUser]} >
          <FormContext.Provider value={[form, setForm]}>
            {children}
          </FormContext.Provider>
        </UserContext.Provider>
      </PermissionContext.Provider>
    </LoggContext.Provider>
  );
}
Store.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Store;
