import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Radio,
  Text,
  HStack,
  FormControl,
  Input,
  Icon
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';

var numeral = require('numeral');


export default function SelectVehicleScreen(props) {
  const { navigation } = props;
  let [form, setForm] = React.useContext(FormContext);

  const [isArriving, setIsArriving] = React.useState(null);
  const [isArrivingPlace, setIsArrivingPlace] = React.useState(null);
  const [working, setWorking] = React.useState(false);

  const handleChange = () => {
    setForm((prev) => {
      return {
        ...prev, ...{ isArriving,isArrivingPlace }
      }
    });
  }

  const continueForm = () => {
    handleChange()
    navigation.navigate('TransportingCompanyScreen')
  }


  if (working) {
    return <PreLoader />;
  }

  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <Box
          rounded="lg"
          overflow="hidden"
          width={{
            base: 'xs',
            sm: 'xl',
            md: 768,
            lg: 992,
            xl: 1280,
          }}
          height="full"
          shadow={1}
          _light={{ backgroundColor: 'gray.50' }}
          _dark={{ backgroundColor: 'gray.700' }}
        >
          <Stack p="4" space={3}>
            <Stack space={2}>
              <Heading size="md" ml="-1">
                {`Inspector: ${form.inspector}`}
              </Heading>
            </Stack>
            <Text fontWeight="400">
              Indique si es un vehículo que arribó a las instalaciones de Virbac o si el vehículo parte de las instalaciones de Virbac
            </Text>
            <Radio.Group
              name="isArriving"
              accessibilityLabel="Estado de vehiculo"
              value={isArriving}
              onChange={(nextValue) => {
                setIsArriving(nextValue)
              }}
            >
              <Radio value={false} my={1}>
                Exportación
                <Icon ml={1} as={Ionicons} name="planet" />
              </Radio>
              <Radio value={true} my={1}>
                Importación
                <Icon ml={1} as={Ionicons} name="map-outline" />
              </Radio>
            </Radio.Group>
            {isArriving !== null && <FormControl isRequired>
              <Stack>
                <FormControl.Label>{isArriving === false ? 'Destino' : 'Origen'}</FormControl.Label>
                <Input onChangeText={(text) => setIsArrivingPlace(text)} placeholder={isArriving === false ? 'Destino' : 'Origen'} />
              </Stack>
            </FormControl>}
          </Stack>
          <Spacer />
          <Box>
            <HStack>
              <Spacer />
              <Button
                disabled={isArriving === null || isArrivingPlace === null}
                rightIcon={<ChevronRightIcon color="white" />}
                colorScheme={
                  isArriving !== null && isArrivingPlace !== null
                    ? "info" : "gray"
                }
                size="md"
                onPress={() => continueForm()}
              >
                Continuar
              </Button>
            </HStack>

          </Box>
        </Box>
      </SafeAreaView>
    </Center>
  );
}
