import Request from '../constants/Request';

export default {
  api: {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    host: 'https://sac.desarrollapps.com/api/v1/',
    hostAnnalise: 'https://mercadomovil.com.mx/api/v1/',
    // host: 'https://api.taki-dev.tingsystems.com/api/v1/',
    // host: 'http://192.168.1.72/api/v3/',
    // host: 'http://192.168.68.81:3000/api/v1/',
    geoHost: 'http://tingsystems.com/api/v1/geo/',
    client_Id: 'CKLZaUXlx9ay0437WgsElHxKLMx0ZW4MFFrzNwG3',
    priceList: false,
    email: 'contacto@cajaporvenir.com.mx',
    store: 'Caja Popular Porvenir',
    route: null,
    logged: false,
  },
};
