import * as React from 'react';
import { Platform, StatusBar, StyleSheet, View, AppState } from 'react-native';

import useCachedResources from './hooks/useCachedResources';
import AppNavigator from './navigation/AppNavigator';
import reloadIfUpdateAvailable from './hooks/reloadIfUpdate';
import Store from './stores/Store';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './constants/firebaseConfig';
import { extendTheme, NativeBaseProvider } from 'native-base';

let appState = AppState.currentState;


export default function App(props) {
  const isLoadingComplete = useCachedResources();

  React.useLayoutEffect(() => {
    try {
      initializeApp(firebaseConfig);
    } catch (error) {
      console.log('firebase error', error);
    }
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    if (
      appState.match(/inactive|background/)
      && nextAppState === 'active'
    ) {
      reloadIfUpdateAvailable()
        .then(() => {
          appState = nextAppState;
        });
    }
  };

  AppState.addEventListener('change', _handleAppStateChange);


  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <NativeBaseProvider>
        <StatusBar backgroundColor="#0B58A8" barStyle="light-content" />
        <Store>
          <AppNavigator />
        </Store>
      </NativeBaseProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
