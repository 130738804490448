import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { ActivityIndicator, Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
    Box,
    Heading,
    Icon,
    AspectRatio,
    Image,
    Text,
    Center,
    HStack,
    Stack,
    NativeBaseProvider,
    FormControl,
    Input,
    WarningOutlineIcon,
    Spacer,
    Button,
    ChevronRightIcon,
    VStack,
    FlatList,
    Actionsheet,
    useDisclose,
    Pressable
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { UserContext } from '../../stores/Store';
import { Entypo, Ionicons, MaterialIcons } from '@expo/vector-icons';
import { collection, getDocs, getFirestore, query, doc, deleteDoc } from "firebase/firestore";
import { SwipeListView } from 'react-native-swipe-list-view';
import Colors from '../../constants/Colors';

var moment = require('moment');


export default function CompaniesScreen(props) {
    const { navigation } = props;
    const [user] = React.useContext(UserContext);
    const firestore = getFirestore();
    const [data, setData] = React.useState([]);
    const [working, setWorking] = React.useState(false);
    const { isOpen, onOpen, onClose } = useDisclose();
    const [deleting, setDeleting] = React.useState(false)

    const getData = async () => {
        setWorking(true)
        const db = firestore;
        const q = query(collection(db, "transporting_companies"),);
        const data = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            data.push({
                ref: doc.id,
                data: doc.data()
            })
        });
        setData(data)
    }
    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // The screen is focused
            getData()
                .then(() => {
                    setWorking(false)
                })
                .catch((error) => {
                    console.log(error);
                    setWorking(false)
                })
        });

        // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
    }, [navigation]);


    const closeRow = (rowMap, rowKey) => {
        if (rowMap[rowKey]) {
            rowMap[rowKey].closeRow();
        }
    };

    const deleteRow = (rowMap, rowKey) => {
        setDeleting(true);
        const db = firestore;
        deleteDoc(doc(db, "transporting_companies", rowKey.ref))
            .then(() => {
                const newData = [...data];
                const prevIndex = data.findIndex((item) => item.ref === rowKey.ref);
                console.log(data[prevIndex]);
                newData.splice(prevIndex, 1);
                // console.log(newData);
                setData(newData);
                setDeleting(false);
            })
            .finally(() => {
                closeRow(rowMap, rowKey);
            })


    };

    const renderItem = ({ item: { data }, index }) => (
        <Box>
            <Pressable onPress={() => console.log('You touched me')} bg="white">
                <Box
                    pl="4"
                    pr="5"
                    py="2"
                    h="70"
                    _dark={{
                        borderColor: "gray.600",
                    }}
                    borderColor="coolGray.200"
                    borderRadius="5"
                    borderWidth="1"

                >
                    <HStack alignItems="center" space={3}>
                        <VStack>
                            <Text color="coolGray.800" _dark={{ color: 'warmGray.50' }} bold>
                                {data.name}
                            </Text>
                        </VStack>
                        <Spacer />
                    </HStack>
                </Box>
            </Pressable>
        </Box>
    );

    const renderHiddenItem = (data, rowMap) => (
        <HStack flex="1" pl="2">
            <Spacer />
            <Pressable
                w="70"
                // cursor="pointer"
                bg="red.500"
                justifyContent="center"
                borderRadius="5"
                onPress={() => deleteRow(rowMap, data.item)}
                _pressed={{
                    opacity: 0.5,
                }}>
                {!deleting ?
                    <VStack alignItems="center" space={2}>
                        <Icon as={<MaterialIcons name="delete" />} color="white" size="xs" />
                        <Text color="white" fontSize="xs" fontWeight="medium">
                            Eliminar
                        </Text>
                    </VStack> :
                    <VStack alignItems="center" space={2}>
                        <ActivityIndicator style={{ height: '40%' }} size="large" color="white" />
                        <Text color="white" style={{ color: 'white' }} fontSize="xs" fontWeight="normal">
                            Eliminando
                        </Text>
                    </VStack>
                }
            </Pressable>
        </HStack>
    );


    if (working) {
        return <PreLoader />;
    }

    return (
        <Center flex={1}>
            <SafeAreaView style={{ flex: 1, padding: 10 }}>
                <Box
                    rounded="lg"
                    overflow="hidden"
                    width={{
                        base: 'sm',
                        sm: 'xl',
                        md: 768,
                        lg: 992,
                        xl: 1280,
                    }}
                    height="full"
                    shadow={1}
                    _light={{ backgroundColor: 'gray.50' }}
                    _dark={{ backgroundColor: 'gray.700' }}
                >
                    <Actionsheet isOpen={isOpen} onClose={onClose}>
                        <Actionsheet.Content>
                            <Box w="100%" h={60} px={4} justifyContent="center">
                                <Text
                                    fontSize="16"
                                    color="gray.500"
                                    _dark={{
                                        color: "gray.300",
                                    }}
                                >
                                    Acciones
                                </Text>
                            </Box>
                            <Actionsheet.Item>Enviar por correo</Actionsheet.Item>
                            <Actionsheet.Item onPress={onClose}>Cancelar</Actionsheet.Item>
                        </Actionsheet.Content>
                    </Actionsheet>
                    <Stack p="4" space={3}>
                        <Stack space={2}>
                            <Heading size="md" ml="-1">
                                {`Empresas transportistas`}
                            </Heading>
                        </Stack>
                        <Spacer />
                        <Box>
                            <SwipeListView
                                data={data}
                                renderItem={renderItem}
                                renderHiddenItem={renderHiddenItem}
                                rightOpenValue={-80}
                                previewRowKey={'0'}
                                previewOpenValue={-40}
                                previewOpenDelay={3000}
                                keyExtractor={(item, index) => item.ref}
                            />
                        </Box>
                    </Stack>

                </Box>
            </SafeAreaView>
        </Center>
    );
}