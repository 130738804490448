import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Radio,
  Text,
  FormControl,
  Input,
  VStack,
  KeyboardAvoidingView,
  ScrollView,
  HStack
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { isFormEnded } from '../../hooks/isFormEnd';

var numeral = require('numeral');


export default function DoorsScreen(props) {
  const { navigation } = props;
  let [form, setForm] = React.useContext(FormContext);
  const [formData, setFormData] = React.useState({});
  const [working, setWorking] = React.useState(false);

  const handleChange = (data) => {
    setFormData((prev) => {
      return {
        ...prev, ...data
      }
    });
  }
  const handleChangeForm = (data) => {
    const formData = { ...form }
    formData.questions.doors = { data, order: 14 };
    console.log(formData);
    setForm({ ...form, ...formData });
  }

  const continueForm = () => {
    handleChangeForm(formData)
    if (formData.loose_screws_doors.value || formData.missing_seals_doors.value || formData.is_seal_broken_doors.value) {
      setWorking(true)
      form.endAt = new Date();
      isFormEnded(() => navigation.navigate('HomeScreen'), form)
    } else {
      navigation.navigate('RoofScreen')
    }

  }


  if (working) {
    return <PreLoader />;
  }


  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <KeyboardAvoidingView
          h={{
            // base: "400px",
            lg: "auto",
          }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <VStack
            rounded="lg"
            overflow="hidden"
            width={{
              base: 'xs',
              sm: 'xl',
              md: 768,
              lg: 992,
              xl: 1280,
            }}
            height="full"
            shadow={1}
            _light={{ backgroundColor: 'gray.50' }}
            _dark={{ backgroundColor: 'gray.700' }}
            flex="1"
            justifyContent="flex-end"
          >
            <ScrollView
              _contentContainerStyle={{
                px: "20px",
                mb: "4",
                minW: "72",
              }}
            >
              <Stack p="4" space={3}>
                <Stack space={2}>
                  <Heading size="md" ml="-1">
                    {`Inspector: ${form.inspector}`}
                  </Heading>
                </Stack>
                <Text fontWeight="400">
                  {`Criterios para evaluar las preguntas siguientes responde `}
                  <Text fontWeight="bold">SI </Text>
                  <Text >o</Text>
                  <Text fontWeight="bold"> NO </Text>
                  {`según corresponda`}
                </Text>
                <Text fontWeight="400">
                  {`14) Puertas exteriores e interiores`}
                </Text>
                <FormControl>
                  <Stack space={2}>
                    <FormControl isRequired>
                      <Stack>
                        <FormControl.Label>Se obervan vulnerados, flojos o sobrepuestos los tornillos, pasadores o tuercas
                        </FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ loose_screws_doors: { value: nextValue, q: 'Se obervan vulnerados, flojos o sobrepuestos los tornillos, pasadores o tuercas', critic: true } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </Stack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.loose_screws_doors.observations = text} placeholder="Observaciones" />
                    </Stack>
                    <FormControl isRequired>
                      <Stack>
                        <FormControl.Label>Al arribo del contenedor, remolque o caja seca no cuenta con sellos</FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ missing_seals_doors: { value: nextValue, q: 'Al arribo del contenedor, remolque o caja seca no cuenta con sellos', critic: true } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </Stack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.missing_seals_doors.observations = text} placeholder="Observaciones" />
                    </Stack>
                    <FormControl>
                      <Stack>
                        <FormControl.Label>Se observa que las puertas están en mal estado, corridas o con enmendaduras</FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ amendments_doors: { value: nextValue, q: 'Se observa que las puertas están en mal estado, corridas o con enmendaduras' } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </Stack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.amendments_doors.observations = text} placeholder="Observaciones" />
                    </Stack>
                    <FormControl>
                      <Stack>
                        <FormControl.Label>Los elementos con los que cuenta la puerta se encuentran el malas condiciones? (Visagras, pernos u otros)
                        </FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ have_all_elements_doors: { value: nextValue, q: 'Los elementos con los que cuenta la puerta se encuentran el malas condiciones? (Visagras, pernos u otros)' } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </Stack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.have_all_elements_doors.observations = text} placeholder="Observaciones" />
                    </Stack>
                    <FormControl>
                      <Stack>
                        <FormControl.Label>Se observa un cambio evidente en los materiales (pintura, se vea más nuevo, soldadura reciente u otros)
                        </FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ new_materials_doors: { value: nextValue, q: 'Se observa un cambio evidente en los materiales (pintura, se vea más nuevo, soldadura reciente u otros)' } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </Stack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.new_materials_doors.observations = text} placeholder="Observaciones" />
                    </Stack>
                    <FormControl isRequired>
                      <VStack>
                        <FormControl.Label>El sello se encuentra violado o la numeración no coincide</FormControl.Label>
                        <Radio.Group
                          name="myRadioGroup"
                          accessibilityLabel=""
                          onChange={(nextValue) => {
                            handleChange({ is_seal_broken_doors: { value: nextValue, q: 'El sello se encuentra violado o la numeración no coincide', critic: true } })
                          }}
                        >
                          <Radio colorScheme="warning" value={true} my={1}>
                            <Text ml='2' fontSize="lg" style={{ color: 'red' }}>SI</Text>
                          </Radio>
                          <Radio value={false} my={1}>
                            NO
                          </Radio>
                        </Radio.Group>
                      </VStack>
                    </FormControl>
                    <Stack>
                      <FormControl.Label>Observaciones (en caso de no cumplir mencionarlo)</FormControl.Label>
                      <Input onChangeText={(text) => formData.is_seal_broken_doors.observations = text} placeholder="Observaciones" />
                    </Stack>

                  </Stack>
                </FormControl>
              </Stack>
            </ScrollView>
            <Spacer />
            <Box>
              <HStack>
                <Spacer />
                <Button
                  disabled={formData.amendments_doors === undefined ||
                    formData.is_seal_broken_doors === undefined &&
                    formData.loose_screws_doors === undefined ||
                    formData.missing_seals_doors === undefined ||
                    formData.new_materials_doors === undefined ||
                    formData.have_all_elements_doors === undefined
                  }
                  rightIcon={<ChevronRightIcon color="white" />}
                  colorScheme={formData.amendments_doors !== undefined &&
                    formData.is_seal_broken_doors !== undefined &&
                    formData.loose_screws_doors !== undefined &&
                    formData.missing_seals_doors !== undefined &&
                    formData.new_materials_doors !== undefined &&
                    formData.have_all_elements_doors !== undefined
                    ? "info" : "gray"
                  }
                  size="md"
                  onPress={() => continueForm()}
                >
                  Continuar
                </Button>
              </HStack>

            </Box>
          </VStack>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </Center>
  );
}
