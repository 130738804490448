import * as React from 'react';
import { Alert } from 'react-native';
import { collection, getFirestore, addDoc } from "firebase/firestore";

const saveDoc = async (form) => {
    console.log(form);
    const firestore = getFirestore();
    // await setDoc(doc(firestore, "forms", uuid.v4()), form);
    await addDoc(collection(firestore, "forms"), form);
}

export function isFormEnded(onPress, form) {
    Alert.alert('Atención', 'Favor de comunicarse con el gerente de seguridad patrimonial.',
        [
            {
                text: 'OK', onPress: () => {
                    saveDoc(form).finally(onPress)

                }
            }
        ])
}