import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
    Box,
    Heading,
    Icon,
    AspectRatio,
    Image,
    Text,
    Center,
    HStack,
    Stack,
    NativeBaseProvider,
    FormControl,
    Input,
    WarningOutlineIcon,
    Spacer,
    Button,
    ChevronRightIcon,
    VStack
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { UserContext } from '../../stores/Store';
import { Ionicons } from '@expo/vector-icons';

var numeral = require('numeral');


export default function MainScreen(props) {
    const { navigation } = props;
    const [user] = React.useContext(UserContext);
    const [working, setWorking] = React.useState(false);


    const continueForm = () => {
    }


    if (working) {
        return <PreLoader />;
    }

    return (
        <Center flex={1}>
            <SafeAreaView style={{ flex: 1, padding: 10 }}>
                <Box
                    rounded="lg"
                    overflow="hidden"
                    width={{
                        base: 'sm',
                        sm: 'xl',
                        md: 768,
                        lg: 992,
                        xl: 1280,
                    }}
                    height="full"
                    shadow={1}
                    _light={{ backgroundColor: 'gray.50' }}
                    _dark={{ backgroundColor: 'gray.700' }}
                >
                    <Stack p="4" space={3}>
                        <Stack space={2}>
                            <Center>
                                <Heading size="md" ml="-1">
                                    {`Bienvenido ${user.email}`}
                                </Heading>
                            </Center>
                        </Stack>
                        <Spacer />
                        <Center>
                            <VStack space="sm">
                                <Stack>
                                    <Button
                                        w="sm"
                                        endIcon={<Icon as={Ionicons} name="document" color="white" />}
                                        colorScheme={"info"}
                                        size="md"
                                        onPress={() => navigation.navigate('InspectionsScreen')}
                                    >
                                        Inspecciones pendientes
                                    </Button>
                                </Stack>
                                <Stack>
                                    <Button
                                        w="sm"
                                        endIcon={<Icon as={Ionicons} name="document-attach" color="white" />}
                                        colorScheme={"info"}
                                        size="md"
                                        onPress={() => navigation.navigate('InspectionsCompleteScreen')}
                                    >
                                        Inspecciones
                                    </Button>
                                </Stack>
                                {user.email !== 'administracion@virbac.com' && <Stack>
                                    <Button
                                        w="sm"
                                        endIcon={<Icon as={Ionicons} name="document-attach" color="white" />}
                                        colorScheme={"info"}
                                        size="md"
                                        onPress={() => navigation.navigate('SignedInspectionsScreen')}
                                    >
                                        Mis inspecciones firmadas
                                    </Button>
                                </Stack>}
                                {user.email === 'seguridadpatrimonial@virbac.com' && <Stack>
                                    <Button
                                        w="sm"
                                        endIcon={<Icon as={Ionicons} name="business-outline" color="white" />}
                                        colorScheme={"info"}
                                        size="md"
                                        onPress={() => navigation.navigate('CompaniesScreen')}
                                    >
                                        Empresas transportistas
                                    </Button>
                                </Stack>}
                            </VStack>
                        </Center>
                    </Stack>

                </Box>
            </SafeAreaView>
        </Center>
    );
}