import * as React from 'react';
import { Alert, Platform, RefreshControl, StyleSheet, ToastAndroid } from 'react-native';
import {
    Box,
    Heading,
    Icon,
    AspectRatio,
    Image,
    Text,
    Center,
    HStack,
    Stack,
    NativeBaseProvider,
    FormControl,
    Input,
    WarningOutlineIcon,
    Spacer,
    Button,
    ChevronRightIcon,
    FlatList,
    VStack,
    useDisclose,
    Actionsheet
} from 'native-base';
import { SafeAreaView } from 'react-native-safe-area-context';
import PreLoader from '../../components/PreLoader';
import { collection, getDocs, getFirestore, query, Timestamp, where } from 'firebase/firestore';
import moment from 'moment';
import { Ionicons } from '@expo/vector-icons';

var numeral = require('numeral');

export default function PendingScreen(props) {
    const { navigation } = props;
    const firestore = getFirestore();
    const [data, setData] = React.useState('');
    const [working, setWorking] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const { isOpen, onOpen, onClose } = useDisclose();

    const getData = async () => {
        setWorking(true)
        var db = firestore;

        const q = query(collection(db, "forms"), where('endAt', "==", 'undefined'));
        const data = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // if (doc.data().complete === false || doc.data().complete === undefined) {
            data.push({
                ref: doc.id,
                data: doc.data()
            })
            // }

        });
        setData(data)
    }


    React.useEffect(() => {
        const unsuscribe = navigation.addListener(('focus'), () => {
            getData()
                .then(() => {
                    setWorking(false)
                })
                .catch((error) => {
                    console.log(error);
                    setWorking(false)
                })
        })

        return unsuscribe;

    }, []);

    const onRefresh = () => {
        getData()
            .then(() => {
                setWorking(false)
            })
            .catch((error) => {
                console.log(error);
                setWorking(false)
            })
    }

    const renderActionSheetOption = (item) => {
        console.log(item.data.pallet_image);
        if (!item.data.pallet_image) {
            return (<Actionsheet.Item onPress={() => {
                navigation.navigate('PendingImagesScreen', {
                    item: item,
                    kind: 'pallet_images'
                })
                onClose()
            }}>Cargar imágen de tarima</Actionsheet.Item>)
        }
        if (!item.data.lock_image) {
            return (<Actionsheet.Item onPress={() => {
                navigation.navigate('PendingImagesScreen', {
                    item: item,
                    kind: 'lock_images'
                })
                onClose()
            }}>Cargar imágen de candado</Actionsheet.Item>)
        }
    }

    if (working) {
        return <PreLoader />;
    }

    return (
        <Center flex={1}>
            <SafeAreaView style={{ flex: 1, padding: 10 }}>
                <Box
                    rounded="lg"
                    overflow="hidden"
                    width={{
                        base: 'xs',
                        sm: 'xl',
                        md: 768,
                        lg: 992,
                        xl: 1280,
                    }}
                    height="full"
                    shadow={1}
                    _light={{ backgroundColor: 'gray.50' }}
                    _dark={{ backgroundColor: 'gray.700' }}
                >
                    <Actionsheet isOpen={isOpen} onClose={onClose}>
                        <Actionsheet.Content>
                            <Box w="100%" h={60} px={4} justifyContent="center">
                                <Text
                                    fontSize="16"
                                    color="gray.500"
                                    _dark={{
                                        color: "gray.300",
                                    }}
                                >
                                    Acciones
                                </Text>
                            </Box>
                            {
                                (selectedItem) &&
                                renderActionSheetOption(selectedItem)

                            }

                            <Actionsheet.Item onPress={onClose}>Cancelar</Actionsheet.Item>
                        </Actionsheet.Content>
                    </Actionsheet>
                    <Stack p="4" space={3} mb="10">
                        <Stack space={2}>
                            <Heading size="md" ml="-1">
                                {`Inspecciones pendientes`}
                            </Heading>
                        </Stack>
                        <Spacer />
                        <FlatList
                            data={data}
                            keyExtractor={(item) => item.ref}
                            refreshControl={
                                <RefreshControl
                                    refreshing={working}
                                    onRefresh={() => onRefresh()}
                                />
                            }
                            renderItem={({ item: { data, ref } }) => {
                                // if (!userCanSign(data) && user.email !== 'administracion@virbac.com')
                                //     return null;
                                return (
                                    <Box
                                        borderWidth="1"
                                        _dark={{
                                            borderColor: "gray.600",
                                        }}
                                        borderColor="coolGray.200"
                                        pl="4"
                                        pr="5"
                                        py="2"
                                        mb="2"
                                        borderRadius="5"

                                    >
                                        <HStack space={1} justifyContent="space-between">
                                            <VStack maxW="42%">
                                                <Text
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    bold
                                                >
                                                    Ispector: {data.inspector}
                                                </Text>
                                                <Text
                                                    color="coolGray.600"
                                                    _dark={{
                                                        color: "warmGray.200",
                                                    }}
                                                >
                                                    Chofer: {data.driver}
                                                </Text>
                                                <Text
                                                    numberOfLines={3}
                                                    ellipsizeMode="tail"
                                                    color="coolGray.600"
                                                    _dark={{
                                                        color: "warmGray.200",
                                                    }}
                                                >
                                                    {data.company}
                                                </Text>
                                                {/* {renderHasVideo(data)} */}
                                            </VStack>
                                            <Spacer />
                                            <VStack maxW="42%">
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    {moment(new Timestamp(data.startAt?.seconds, data.startAt?.nanoseconds).toDate()).format('DD/MM/YYYY')}
                                                </Text>
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    Inicio: {moment(new Timestamp(data.startAt?.seconds, data.startAt?.nanoseconds).toDate()).format('hh:mm A')}
                                                </Text>
                                                {/* <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    Fin: {moment(new Timestamp(data.endAt?.seconds, data.endAt?.nanoseconds).toDate()).format('hh:mm A')}
                                                </Text> */}
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    <Icon size="xs" as={Ionicons} name={data.isArriving ? "arrow-up-outline" : "arrow-down-outline"} />
                                                    {data.isArriving ? 'Exportación' : 'Importación'}
                                                </Text>
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    {data.vehicle}
                                                </Text>
                                                <Text
                                                    fontSize="xs"
                                                    _dark={{
                                                        color: "warmGray.50",
                                                    }}
                                                    color="coolGray.800"
                                                    alignSelf="flex-end"
                                                >
                                                    Sello: {data.stampNumber}
                                                </Text>
                                                <Spacer />

                                            </VStack>
                                            <Icon onPress={() => {
                                                setSelectedItem({ data, ref })
                                                onOpen()
                                            }} alignSelf="flex-start" as={Ionicons} name="menu" />
                                        </HStack>
                                    </Box>
                                )
                            }}
                            ListEmptyComponent={() => (
                                <Box
                                    borderWidth="1"
                                    _dark={{
                                        borderColor: "gray.600",
                                    }}
                                    borderColor="coolGray.200"
                                    pl="4"
                                    pr="5"
                                    py="2"
                                    mb="2"
                                    borderRadius="5"

                                >
                                    <HStack space={1} justifyContent="space-between">
                                        <VStack maxW="100%">
                                            <Text
                                                _dark={{
                                                    color: "warmGray.50",
                                                }}
                                                color="coolGray.800"
                                                bold
                                            >
                                                Sin información para mostrar
                                            </Text>

                                        </VStack>
                                    </HStack>
                                </Box>
                            )}
                        />
                    </Stack>
                </Box>
            </SafeAreaView>
        </Center>
    );
}