import * as React from 'react';
const moment = require('moment');
import { Timestamp } from "firebase/firestore";

export default function HtmlForm(form, image, sections, signatures, vehicles_images, formImages) {
    const { ref, data } = form;
    const { questions } = data;
    const responsibles = [
        'Resp. de la inspección',
        'Supervisor',
        'Resp. de Comercio Exterior',
        'Resp. De Seguridad Patrimonial'
    ]
    const [
        driver_circulation_card_image,
        driver_licence_image,
        plate_number_image,
        lock_image,
        pallet_image,
    ] = formImages;
    var values = Object.keys(questions).map(function (key) { return questions[key]; });
    values.sort((a, b) => a.order > b.order);
    const renderFormQuestion = (questionText, isCritic, response, observations) => {
        if (response !== undefined) {
            if (observations !== undefined) {
                return (
                    `
                    <tr>
                        <td style="color:${isCritic ? "red" : "black"}">${questionText}</td>
                        <td>${response ? "SI" : "NO"}</td>
                        <td>${observations}</td>
                    </tr>
                    `
                )
            }
            return (
                `
                <tr>
                        <td style="color:${isCritic ? "red" : "black"}">${questionText}</td>
                        <td>${response ? "SI" : "NO"}</td>
                        <td> </td>
                </tr>
                `
            )
        }
        return null;
    }

    const renderFormSection = (item) => {
        const { data, order } = item;
        var values = Object.keys(data).map(function (key) { return data[key]; });
        values.sort((a, b) => a.order > b.order);
        const section = sections.find((element) => element.order === order)
        const questions = values.map((item) => renderFormQuestion(
            item.q,
            item.critic ? item.critic : false,
            item.value,
            item.observations
        ))
        return (
            `
            <tr>
                <td colspan="3">${`${section.order}) ${section.name}`}</td>
            </tr>
            ${questions}
            `
        )
    }

    const renderVehiclesImages = (images) => {
        const vehicleImages = images.map((element => {
            if(!element) return;
            return (
                `
                <div class="col-3" style="padding:10px">
                    <img style="width:150px;height:150px;" alt="${element}" src=${element} />
                </div>
                    
                `
            )
            // <td><img style="width:100px;height:100px;" alt="${element}" src=${element} /></td>
        }));

        return (
            `
            <div class="container" style="margin:10px">
                <div class="row">
                    <div class="col-sm-8">Imagenes del vehiculo</div>
                </div>
                <div class="row">
                    ${vehicleImages}
                </div>
            </div>
            
            `
            // <tr>
            //     <td>Imagenes del vehiculo</td>
            //     ${vehicleImages}
            // </tr>
        );
    }


    const renderIsValid = (item) => {
        // require('./base.min.css')
        if (item) return 'SI';
        return 'NO';
    }

    const checkAprovationLevel = (form) => {
        let level = 0;
        if (form.supervisor === undefined) {
            return 1
        }
        if (form.external_commerce_resp === undefined) {
            return 2
        }
        if (form.patrimonial_security_resp === undefined) {
            return 3
        }
        return level;
    };

    const renderIsAproved = (data) => {
        if (data.is_aproved) {
            if (checkAprovationLevel(data) === 0) {
                return (
                    `<h4 style="color:green">Aprobada</h4>`
                )
            }
            if (data.supervisor === undefined) {
                return (
                    `<h4 style="color:orange">Pendiente de aprobación</h4>`
                )
            }
            if (data.external_commerce_resp === undefined) {
                return (
                    `<h4 style="color:orange">Pendiente de aprobación</h4>`
                )
            }
            if (data.patrimonial_security_resp === undefined) {
                return (
                    `<h4 style="color:orange">Pendiente de aprobación</h4>`
                )
            }
            return (
                `<h4 style="color:green">Aprobada</h4>`
            )
        } else {
            return (
                `<h4 style="color:red">No Aprobada</h4>`
            )
        }
    }

    const sectionsForm = values.map((item) => renderFormSection(item))
    const formHtml = `
    <!DOCTYPE html>
<html>
<style>
    table,
    th,
    td {
        border: 1px solid black;
    }
</style>
<head>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
</head>
<body>
    <table style="width:100%">
        <tr>
            <th style="border:0px">${image}</th>
            <th style="border:0px; width:70%">
                <h3>Inspección de los 17 puntos.</h3>
            </th>
            <th style="border:0px">FOLIO: ${ref}</th>
        </tr>
        <tr>
            <td style="width:60%">EMPRESA TRANSPORTISTA: ${data.company}</td>
            <td style="border:0px; width:10%">HORA INCIAL DE LA INSPECCIÓN: ${moment(new Timestamp(data.startAt.seconds,
                data.startAt.nanoseconds).toDate()).format('hh:mm A')}</td>
            <td style="border:0px;width:10%">HORA FINAL DE LA
                INSPECCIÓN: ${moment(new Timestamp(data.startAt.seconds,
                data.startAt.nanoseconds).toDate()).format('hh:mm A')}
            </td>
            <td style="border:0px;width:10%">FECHA: ${moment(new Timestamp(data.startAt.seconds,
                data.startAt.nanoseconds).toDate()).format('DD/MM/YYYY')}
            </td>
        </tr>
       
        <tr>
            <td style="border: 0px">
                <table style="border: 0px">
                    <tr style="border: 0px">
                        <td style="border: 0px">LICENCIA DE CHOFER: ${data.driver_licence_number}</td>
                    </tr>
                    <tr style="border: 0px">
                        <td style="border: 0px">Vigencia: ${data.is_licence_valid ? 'Vigente' : 'No vigente'}</td>
                    </tr>
                    <tr style="border: 0px">
                        <td><img style="width:150px;height:150px;" alt="${driver_licence_image}" src=${driver_licence_image} /></td>
                    </tr>
                </table>
            </td>
            <td style="width:10%">Numero de sello: ${data.stampNumber}</td>
            <td colspan="3">TIPO DE VEHÍCULO: ${data.vehicle}
        </tr>
        <tr>
            <td style="border: 0px">
                <table style="border: 0px">
                        <tr style="border: 0px">
                            <td>TARJETA DE CIRCULACIÓN VALIDA: ${renderIsValid(data.is_circulation_card_valid)}</td>
                        </tr>
                        <tr>
                            <td><img style="width:150px;height:150px;" alt="${driver_circulation_card_image}" src=${driver_circulation_card_image} /></td>
                        </tr>
                </table>
            </td>         
            <td style="border: 0px">
                <table style="border: 0px">
                    <tr style="border: 0px">
                        <td>PLACAS: ${data.plate_number}</td>
                    </tr>
                    <tr style="border: 0px">
                        <td><img style="width:150px;height:150px;" alt="${plate_number_image}" src=${plate_number_image} /></td>
                    </tr>
                </table>
            </td>
            <td rowspan="4">MEDIDAS</td>
            <td>Internas</td>
            <td>Externas</td>
        </tr>
        <tr>
            <td colspan="2" rowspan="3" style="border: 0px">Cumple el Conductor con lo declarado en la
                Notificación: ${renderIsValid(data.isDeclarationValid)} especifica: ${data.declarationObservations}
            </td>
            <td style="border: 0px">Alto: ${data.ExternHeight}</td>
            <td style="border: 0px">Alto: ${data.InternHeight}</td>
        </tr>

        <tr>
            <td style="border: 0px">Ancho: ${data.ExternWidth}</td>
            <td style="border: 0px">Ancho: ${data.InternWidth}</td>
        </tr>
        <tr>
            <td style="border: 0px">Largo: ${data.ExternLenght}</td>
            <td style="border: 0px">Largo: ${data.InternLenght}</td>
        </tr>
    </table>
        ${renderVehiclesImages(vehicles_images)}
        <table style="width:100%">
            <tr>
                <th colspan="2">CRITERIOS PARA EVALUAR LAS PREGUNTAS SIGUIENTES
                    RESPONDE O NO SEGÚN CORRESPONDA.
                </th>
                <th>
                    OBSERVACIONES (en caso de no cumplir, mencionarlo)
                </th>
            </tr>
            ${sectionsForm}
           
        </table>
        <div class="container">
            <div class="row">
                <div class="col-sm">
                IMAGEN TARIMA
                </div>
                <div class="col-sm">
                IMAGEN CANDADO
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <img style="width:250px;height:250px;" alt="${pallet_image}" src=${pallet_image} />
                </div>
                <div class="col-sm">
                    <img style="width:250px;height:250px;" alt="${lock_image}" src=${lock_image} />
                </div>
            </div>
        </div>
        <table style="width:100%">
            <tr>
                <th colspan="3">Evaluación de criterios
                </th>
                <th>
                    ${renderIsAproved(data)}
                </th>
            </tr>
            <tr>
                ${signatures.map((src, index) => {
                if (src === "") {
                return '<td />'
                } else {
                return `<td><img style="width:150px;height:150px;" alt="signature" src=${src} /></td>`
                }
                })}
            </tr>
            <tr>
                ${signatures.map((src, index) => {
                return `<td>${responsibles[index]}</td>`
                })}
            </tr>
        </table>

</body>

</html>
`;

    return (formHtml);
}