import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Alert, Platform, StyleSheet, ToastAndroid } from 'react-native';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Radio,
  Text,
  Select,
  CheckIcon,
  FormControl,
  Input,
  HStack,
  Icon
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { doc, collection, getFirestore, addDoc } from "firebase/firestore";
import { Ionicons } from '@expo/vector-icons';

var numeral = require('numeral');


export default function CreateTransportingCompanyScreen(props) {
  const { navigation } = props;
  const firestore = getFirestore();
  let [form, setForm] = React.useContext(FormContext);
  const [company, setCompany] = React.useState("")
  const [companies, setCompanies] = React.useState([]);
  const [showInput, setShowInput] = React.useState(false);
  const [working, setWorking] = React.useState(false);

  const saveDoc = async () => {
    await addDoc(collection(firestore, "transporting_companies"), { name: company });
  }

  const saveCompany = () => {
    setWorking(true);
    saveDoc()
      .then(() => {
        Alert.alert('Empresa transportista', `La empresa transportista ${company} se registró correctamente`,
        [
          {text: 'Ok', onPress: () => navigation.navigate('CompaniesScreen')}
        ])
        setWorking(false);
      })
      .catch(() => {
        setWorking(false);
      })
  }

  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <Box
          rounded="lg"
          overflow="hidden"
          width={{
            base: 'xs',
            sm: 'xl',
            md: 768,
            lg: 992,
            xl: 1280,
          }}
          height="full"
          shadow={1}
          _light={{ backgroundColor: 'gray.50' }}
          _dark={{ backgroundColor: 'gray.700' }}
        >
          <Stack p="4" space={3}>
            <Stack space={2}>
              <Heading size="md" ml="-1">
                {`Indique la empresa transportista`}
              </Heading>
            </Stack>
            <FormControl isRequired>
              <Stack>
                <FormControl.Label>Escribe el nombre de la empresa transportista</FormControl.Label>
                <Input onChangeText={(text) => setCompany(text)} placeholder="Compañia" />
              </Stack>
            </FormControl>
          </Stack>

          <Spacer />
          <Box>
            <HStack>
              <Spacer />
              <Button
                isLoading={working}
                disabled={company === ''}
                rightIcon={<Icon as={Ionicons} name="save" color="white" />}
                colorScheme={company !== '' ? "info" : "gray"}
                size="md"
                onPress={() => saveCompany()}
              >
                Guardar
              </Button>
            </HStack>

          </Box>
        </Box>
      </SafeAreaView>
    </Center>
  );
}
