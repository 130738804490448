import React from 'react';
// import { Image } from 'react-native';
import { Box, Center, HStack, Image, Spacer, Text, View } from 'native-base';
import { Dimensions, Platform } from 'react-native';

const logo = require('../assets/images/header.png');
const { width, height } = Dimensions.get('window');
console.log(width);
function LogoTitle(props) {
  const { title } = props;
  if (title) {
    return (
      <Box flex="1">
        <HStack>
          <Image
            alt="logo"

            source={logo}
            width={
              {
                base: 'xs',
                sm: 'xs'
              }
            }
            resizeMode="stretch"
            height={{
              base: "16",
              sm: '20'
            }}
          />
          <Text color="white" m="2" fontSize="2xl">{title}</Text>
        </HStack>
      </Box>
    );
  }
  if (Platform.OS === 'web') {
    return (
      // <Center flex="1"
      //   // width={{
      //   //   base: '80',
      //   //   md: '80',
      //   //   sm: '80',
      //   //   lg: width - 100,
      //   // }}
      //   marginLeft={{
      //     base: 0,
      //     lg: -20
      //   }}
      // >
        <Image
          alt="logo"
          alignSelf='center'
          marginX={{
            base: "5",
            sm: '16'
          }}
          source={logo}
          width={
            {
              base: 'xs',
              sm: 'sm'
            }
          }
          resizeMode="stretch"
          height={{
            base: "16",
            sm: '20'
          }}
        />
      // </Center>

    );
  }
  return (
    <Center flex="1">
      <Image
        alt="logo"
        marginX={{
          base: "5",
          sm: '16'
        }}
        source={logo}
        width={
          {
            base: 'xs',
            sm: 'sm'
          }
        }
        resizeMode="stretch"
        height={{
          base: "16",
          sm: '20'
        }}
      />
    </Center>

  );
}

export default LogoTitle;
