import * as React from 'react';
import {
  Box,
  Heading,
  Center,
  Stack,
  Spacer,
  Button,
  ChevronRightIcon,
  Text,
  FormControl,
  Input,
  HStack,
  CheckIcon,
  CloseIcon,
  Icon,
  VStack,
  View
} from 'native-base';
import { FormContext } from '../../stores/Store.tsx';
import PreLoader from '../../components/PreLoader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { collection, getDocs, getFirestore, query, setDoc, doc, addDoc } from "firebase/firestore";
import { Ionicons } from '@expo/vector-icons';
import { Alert } from 'react-native';
import Signature from 'react-native-signature-canvas';
import { getStorage, ref, uploadBytes } from '@firebase/storage';
import { ScrollView } from 'react-native-gesture-handler';

export default function ValidationFormScreen(props) {
  const { navigation } = props;
  const firestore = getFirestore();
  let [form, setForm] = React.useContext(FormContext);
  const [formData, setFormData] = React.useState({});
  const [isAproved, setIsAproved] = React.useState(false);
  const [working, setWorking] = React.useState(false);
  const [criticalAnswers, SetCritialAnswers] = React.useState([])
  const [signature, setSignature] = React.useState(null);

  React.useEffect(() => {
    const getData = async () => {
      setWorking(true)
      var db = firestore;
      const q = query(collection(db, "critical_questions"),);
      const data = []
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        data.push(doc.data().field)
      });
      console.log(data);
      SetCritialAnswers(data)
    }
    getData()
      .then(() => {
        const ban = calculateAprovation()
        setIsAproved(ban);
        handleChange({ is_aproved: ban })
        setWorking(false)
      })
      .catch((error) => {
        console.log(error);
        setWorking(false)
      })
  }, []);

  const calculateAprovation = () => {
    let critical_counter = 0;
    let counter = 0;
    for (let prop in form) {
      const find = criticalAnswers.findIndex((element) => element === prop)
      if (find !== -1) {
        //es critica
        if (form[prop] === true) {
          console.log('critica', prop);
          critical_counter += 1;
        }
      } else {
        switch (prop) {
          case 'isArriving':
            break;
          case 'is_licence_valid':
            break;
          case 'is_circulation_card_valid':
            break;
          case 'isDeclarationValid':
            break;
          case 'is_aproved':
            break;
          default:
            //no es critica
            if (form[prop] === true) {
              console.log('no critica', prop);
              counter += 1;
            }
            break;
        }

      }
    }
    if (critical_counter >= 1) {
      return false;
    } else {
      if (counter >= 5) {
        return false;
      } else {
        return true;
      }
    }
  }

  const handleChange = (data) => {
    setFormData((prev) => {
      return {
        ...prev, ...data
      }
    });
  }

  const handleChangeForm = (data) => {
    form = { ...form, ...data }
    setForm(form);
  }

  const saveDoc = async () => {
    console.log(form);
    // await setDoc(doc(firestore, "forms", uuid.v4()), form);
    return addDoc(collection(firestore, "forms"), form);
  }

  async function storeImage(uri) {
    setWorking(true);
    const storage = getStorage();
    let filename = uri.split('/').pop();
    const refImage = ref(storage, 'signatures/' + filename)
    uploadBytes(refImage, uri).then((snapshot) => {
      formData['inspector_signature'] = 'signatures/' + filename;
      formData['complete'] = false;
      continueForm();
    })
      .catch((error) => {
        console.log(error);
        Alert.alert('Error al guardar la firma, por favor espera un momento e intenta nuevamente', JSON.stringify(error))
        setSignature(null)
        setWorking(false);
      })

  }

  const continueForm = () => {
    setWorking(true);
    handleChangeForm(formData)
    saveDoc().then((ref) => {
      
      Alert.alert('Atención',
        'La solicitud fue creada correctamente',
        [
          {
            text: 'Ok', onPress: () => {
              setWorking(false);
              setForm({})
              navigation.navigate('HomeScreen')
            }
          }
        ])

    })
      .catch(() => {
        setWorking(false);
      })

  }

  const confirmVisit = (signature) => {
    setSignature(signature)
  }


  if (working) {
    return <PreLoader />;
  }

  const renderAprovation = () => {
    if (isAproved) {
      return (
        <Center mt={3}>
          <VStack space={3}>
            <Text fontSize="md">Solicitud: </Text>
            <HStack space={3}>
              <Stack>
                <CheckIcon style={{ color: "orange" }} />
              </Stack>
              <Stack>
                <Text style={{ color: "orange" }} fontSize="md">Pendiente de aprobación</Text>
              </Stack>
            </HStack>
          </VStack>
        </Center>
      )
    }
    return (
      <Center>
        <HStack space={3}>
          <Text fontSize="xl">Solicitud: </Text>
          <Stack>
            <CloseIcon style={{ color: "red" }} />
          </Stack>
          <Stack>
            <Text style={{ color: "red" }} fontSize="xl">No aprobada</Text>
          </Stack>
        </HStack>
      </Center>
    )
  }

  const style = `.m-signature-pad--footer
    .button {
      background-color: red;
      color: #FFF;
    }`;


  return (
    <Center flex={1}>
      <SafeAreaView style={{ flex: 1, padding: 10 }}>
        <Box
          rounded="lg"
          overflow="hidden"
          width={{
            base: 'xs',
            sm: 'xl',
            md: 768,
            lg: 992,
            xl: 1280,
          }}
          height="full"
          shadow={1}
          _light={{ backgroundColor: 'gray.50' }}
          _dark={{ backgroundColor: 'gray.700' }}
        >
          {/* <ScrollView> */}


          <Stack p="4" space={3}>
            <Stack space={2}>
              <Heading size="md" ml="-1">
                {`Inspector: ${form.inspector}`}
              </Heading>
            </Stack>
            <Text fontWeight="400">
              {`Evaluación de criterios`}
            </Text>

          </Stack>
          <View style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: 550,
            padding: 10,
          }}>

            <Signature
              style={{ alignSelf: 'center' }}
              onOK={(img) => confirmVisit(img)}
              onClear={() => setSignature(null)}
              descriptionText="Firmar"
              clearText="Limpiar"
              confirmText="Confirmar"
              webStyle={style}
            // webStyle={`.m-signature-pad--footer
            // .button {
            // background-color: red;
            // color: #FFF;}`}
            />
          </View>
          {/* </ScrollView> */}
          <Box>
            <HStack>
              <Spacer />
              <Button
                m={5}
                disabled={signature === null
                }
                leftIcon={<Icon as={<Ionicons name="save" />} color="white" />}
                colorScheme={signature !== null
                  ? "info" : "gray"
                }
                size="md"
                onPress={() => {
                  Alert.alert('Atención',
                    'A partir de ese momento finalizará la solicitud',
                    [
                      { text: 'Cancelar' },
                      {
                        text: 'Ok', onPress: () => {
                          if (form.isArriving) {
                            form.endAt = new Date();
                          } else {
                            form.endAt = 'undefined';
                          }

                          // form.isAproved = isAproved;
                          storeImage(signature)
                        }
                      }
                    ])
                }}
              >
                {form.isArriving ? "Continuar" : "Terminar solicitud"}
              </Button>
            </HStack>
          </Box>
        </Box>
      </SafeAreaView>
    </Center>
  );
}
